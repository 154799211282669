import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
// import Nprogress from "nprogress";
import { BASE_URL as API_URL, REQUEST_TIMEOUT } from "../config/constant";
import { fetchUserToken, clearData, storeExpiredToken } from "../storage";
import { APIResponseSuccessModel } from "../../types";
import routes from "../../routes";
import Language from "../../utils/language/en";
import { clearSessionData } from "../storage";

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

/** general headers **/
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/** authorization header for logged in user **/
const setAuthorization = () => ({
  Authorization: `Bearer ${fetchUserToken()}`,
});

/** axios instance **/
export const instance = axios.create({
  baseURL: API_URL,
  headers,
  withCredentials: false,
});

/** timeout configuration for axios instance **/
instance.defaults.timeout = REQUEST_TIMEOUT;

// Create a cancellation token source
const cancelTokenSource = axios.CancelToken.source();

// Set the cancellation token as a default config option for the instance
instance.defaults.cancelToken = cancelTokenSource.token;

// Set the cancellation token as a default header in the requests
// instance.defaults.headers.common["error"] = "";

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      // config.headers["Cancel-Token"] = cancelTokenSource.token as any;
      // config.cancelToken = cancelTokenSource.token;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Export the cancellation token source
export const cancelToken = cancelTokenSource;

let originalConfig: any; // eslint-disable-line

/** axios interceptor to trigger a logout on unauthorized error response code **/
instance.interceptors.response.use(
  ({ data }: AxiosResponse): AxiosResponse<APIResponseSuccessModel> => {
    return data;
  },
  (error: AxiosError) => {
    if (error.code === "ERR_CANCELED") {
      return Promise.reject({});
    }
    if (error.response?.status !== undefined && error.response.status >= 500) {
      return Promise.reject({
        message: Language.networkErrorMessage.serverError,
      });
    }
    if (error.response?.status === 401) {
      if (window.location.pathname.includes("auth")) {
        return Promise.reject(error.response.data);
      }
      if (
        window.location.pathname.includes(
          routes.protected.onboarding.terms.index
        )
      ) {
        clearSessionData();
        window.location.replace(routes.nonprotected.welcome);
        storeExpiredToken(true);
        return Promise.reject(error.response.data);
      }
      // const temp: any = store.getState();
      // if (temp?.auth?.isRefreshingToken) {
      //   return new Promise(() => {});
      // } else {
      clearData();
      window.location.replace(routes.nonprotected.login);
      storeExpiredToken(true);
      // }

      // if (!originalConfig) {
      //   originalConfig = error.config;
      // }
      // if (!originalConfig._retry) {
      //   originalConfig._retry = true;
      //   store.dispatch(refreshUserToken(true));
      //   return;
      // }
      // if (originalConfig._retry === true) {
      //   return;
      // }
    }
    if (error.response?.status === 429) {
      if (
        window.location.pathname.includes("auth") &&
        window.location.pathname.includes(routes.nonprotected.login) === false
      ) {
        clearData();
        window.location.replace(routes.nonprotected.login);
      }
    }
    if (error.response?.status === 403) {
      const agreement_complete =
        store.getState()?.auth?.company?.agreement_info?.signature?.complete;

      if (agreement_complete) {
        window.location.replace(routes.protected.onboarding.shareholders.index);
      } else {
        window.location.replace(routes.protected.onboarding.terms.index);
      }
      return Promise.reject(error.response.data);
    }

    return Promise.reject(
      error
        ? error.response
          ? error.response.data
          : { message: Language.networkErrorMessage.genericErrorMessage }
        : { message: Language.networkErrorMessage.genericErrorMessage }
    );
  }
);

/** make an axios get request **/
export const makeGetRequest = (path: string) => instance.get(path);

/** make an axios post request **/
export const makePostRequest = (path: string, payload: any) =>
  instance.post(path, payload);

/** make an axios request for a guest **/
export const makeUnauthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url,
    data,
    headers,
  });
  return response;
};

/** make an axios request for logged-in user **/
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};
export const makeAuthorizedRequestDownload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
    responseType: "arraybuffer",
  });
  return response;
};

/** make an axios request to submit a file for a logged in user **/
export const makeAuthorizedRequestWithFormData = async (
  method: string,
  url: string,
  data: Record<string, any>
) => {
  /** create new formdata object **/
  let formData = new FormData();

  const headers = {
    "Content-Type": "multipart/form-data",
    ...setAuthorization(),
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let fieldData = data[key];
      switch (key) {
        case "channels":
        case "app_color_theme":
        case "app_features":
        case "include_data":
          formData.append(key, JSON.stringify(fieldData));
          break;
        // case "include_data":
        //   const fieldDataKeys = Object.keys(fieldData) as Array<
        //     keyof typeof fieldData
        //   >;
        //   break;

        default:
          formData.append(key, fieldData);
          break;
      }
    }
  }

  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};
export const makeUnAuthorizedRequestWithFormData = async (
  method: string,
  url: string,
  data: Record<string, any>
) => {
  /** create new formdata object **/
  let formData = new FormData();

  let headers = {
    "Content-Type": "multipart/form-data",
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let fieldData = data[key];
      switch (key) {
        default:
          formData.append(key, fieldData);
          break;
      }
    }
  }

  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};
