import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { MOCK } from "../../../common/service/config/mock";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import { mockResponseHandler } from "../../../common/utils/helpers";
import * as tp from "../types";

const service = {
  async getUserInfo(): Promise<tp.APIResponseModel<tp.AuthSuccessRes>> {
    const { method, url } = ENDPOINTS.setting.get_user_info;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateUserProfile(
    data: tp.Itoggle2fa
  ): Promise<tp.APIResponseModel<null>> {
    const { method, url } = ENDPOINTS.setting.update_user_profile;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async updatePassword(data: tp.IupdatePassword) {
    const { method, url } = ENDPOINTS.setting.update_password;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async initiatePasswordUpdate() {
    const { method, url } = ENDPOINTS.setting.initiate_password_update;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async resendPasswordUpdate() {
    const { method, url } = ENDPOINTS.setting.resend_password_update;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getActivityLog() {
    const { method, url } = ENDPOINTS.setting.resend_password_update;
    if (process.env.REACT_APP_MOCK === "true") {
      return mockResponseHandler(MOCK.setting.mockActivityLog, null);
    }
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
