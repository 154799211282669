import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../common/components/CustomButton";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../common/components/ModalTitleHeader";
import { useAppSelector, useEffectOnce } from "../../../../../common/hooks";
import routes from "../../../../../common/routes";
import Language from "../../../../../common/utils/language/en";

const { policy_rules: PageDictionary } =
  Language.protected.ApplicationPolicyModal;

export function PolicyRules() {
  const { appid, policyid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const policy_list = useAppSelector((state) => state.app.app_policies);
  const policy = useMemo(
    () => policy_list.data.find((policy) => policy._id === policyid),
    [policyid] //eslint-disable-line
  );
  const [data, setData] = useState<Record<string, string>[]>([]);

  useEffectOnce(() => {
    if (!policy) return;
    const keys = Object.keys(PageDictionary.rules) as Array<
      keyof typeof PageDictionary.rules
    >;
    const tempData = keys.map((key) => ({
      label: PageDictionary.rules[key],
      data: policy[key],
    }));
    setData(tempData);
  });

  const editHandler = () => {
    if (!appid || !policyid) return;
    const path = routes.protected.applications.policy.update_policy.link(
      appid,
      policyid
    );
    navigate(path, { state });
  };

  const backDropHandler = () => {
    if (!appid) return;
    const path = routes.protected.applications.policy.link(appid);
    navigate(path, { replace: true });
  };

  return (
    <SideModal
      className="flex flex-col p-8 md:pt-22 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <ModalTitleHeader title={PageDictionary.title} subtitle="" />
      <section className="pt-8">
        <header>
          <h4 className="mb-4.5 text-2017 font-bold text-gray-430">{`${
            policy?.type === "CREDIT"
              ? PageDictionary.type.incoming
              : PageDictionary.type.outgoing
          }`}</h4>
        </header>
        <div className="grid gap-y-4.5 pt-4.5">
          {data.map((itm, idx) => (
            <div key={idx} className="flex items-center justify-between">
              <p className="text-sm font-medium leading-6 text-gray-470">
                {itm.label}
              </p>
              <p className="text-2017 font-semibold text-gray-430">
                {itm.data}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section className="flex h-full flex-1 items-end">
        <CustomButton
          onClick={editHandler}
          className="bg-red-10 hover:border hover:border-red-10 hover:bg-transparent hover:text-red-10 hover:transition-all"
        >
          {PageDictionary.edit_button}
        </CustomButton>
      </section>
    </SideModal>
  );
}
