import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
// import { useCancelOnUnmount, useEffectOnce } from "../../../common/hooks";
import * as CreateApplication from "./CreateApplication";
import * as Policy from "./Policy";
import ShowApiScreen from "./ShowApiScreen";
import * as Transaction from "./Transaction";
import * as Include from "./Include";

function ApplicationModal() {
  // const cancelPendingRequests = useCancelOnUnmount();
  // useEffectOnce(() => {
  //   return () => {
  //     cancelPendingRequests();
  //   };
  // });
  return (
    <Routes>
      <Route
        path={routes.protected.applications.create_new_app.create}
        element={<CreateApplication.CreateApplicationForm />}
      />
      <Route
        path={routes.protected.applications.create_new_app.update.index}
        element={<CreateApplication.CreateApplicationForm />}
      />
      <Route
        path={routes.protected.applications.transaction_information.index}
        element={<Transaction.TransactionInformation />}
      />
      <Route
        path={routes.protected.applications.report_transaction.index}
        element={<Transaction.ReportTransaction />}
      />

      {/* Users */}
      <Route
        path={routes.protected.applications.users.block_user.index}
        element={<Policy.BlockUser />}
      />

      {/* policy */}
      <Route
        path={routes.protected.applications.policy.create_policy.index}
        element={<Policy.CreatePolicy />}
      />
      <Route
        path={routes.protected.applications.policy.update_policy.index}
        element={<Policy.CreatePolicy />}
      />
      <Route
        path={routes.protected.applications.policy.view_policy.index}
        element={<Policy.PolicyRules />}
      />
      <Route
        path={routes.protected.applications.policy.create_category.index}
        element={<Policy.CreateCategory />}
      />
      <Route
        path={routes.protected.applications.policy.update_category.index}
        element={<Policy.CreateCategory />}
      />
      <Route
        path={routes.protected.applications.policy.block_user.index}
        element={<Policy.BlockUser />}
      />
      <Route
        path={
          routes.protected.applications.policy.notification_config.add.index
        }
        element={<Policy.CreateNotification />}
      />

      {/* Api setting confirmation */}
      <Route
        path={
          routes.protected.applications.api_settings.shown_api_setting.index
        }
        element={<ShowApiScreen />}
      />
      <Route
        path={routes.protected.applications.api_settings.regen_api_keys.index}
        element={<ShowApiScreen />}
      />
      {/* <Route
        path={
          routes.protected.applications.api_settings.download_api_keys.index
        }
        element={<ShowApiScreen />}
      /> */}

      {/* include */}
      <Route
        path={routes.protected.applications.include.toggle_include.on.index}
        element={<Include.TurnOnInclude />}
      />
      <Route
        path={routes.protected.applications.include.toggle_include.off.index}
        element={<Include.TurnOffInclude />}
      />
    </Routes>
  );
}

export default ApplicationModal;
