import React, { useCallback, useState } from "react";
/* @ts-ignore */
import Papa from "papaparse";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import CustomInput from "../../../../../common/components/CustomInput";
import CustomButton from "../../../../../common/components/CustomButton";
import MediaUpload from "../../../components/MediaUpload";
import { Iapp, iHandleChange, iHandleSubmit } from "../../../types";
import {
  useAppDispatch,
  useAppSelector,
  useCancelOnUnmount,
  useEffectOnce,
} from "../../../../../common/hooks";
import { includeSwitchOn } from "../../../store/thunk";
import Language from "../../../../../common/utils/language/en";

import { tW } from "../../../../../common/utils/helpers";

const { turnOnInclude: PageDictionary } = Language.protected.IncludeModals;

type iKeys = "upload" | "input" | "";

const initialData = {
  app_id: "",
  id: "",
  email: "",
  merchant_id: "",
  app_name: "",
  public_key: "",
  secret_key: "",
};

export const TurnOnInclude = () => {
  useCancelOnUnmount();
  const appid = useParams()?.appid as string;
  const { state } = useLocation();
  const bgPathname = state?.background.pathname as string;
  const { data: allApps, loading } = useAppSelector(
    (state) => state.app.allApps
  );
  const app = allApps.find((app) => app._id === appid) as Iapp;
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [inData, setInData] = useState(initialData);
  const [secretKeyMode, setsecretKeyMode] = useState<iKeys>("");

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value, name } = e.target;
    setsecretKeyMode(value ? "input" : "");
    setInData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const uploadHandler = (files: any) => {
    const file = files?.[0];

    if (file) {
      Papa.parse(file, {
        complete: (result: any) => {
          const keys = result.data[0];
          setsecretKeyMode("upload");
          setInData((prev) => ({
            ...prev,
            public_key: keys?.public,
            secret_key: keys?.secret,
          }));
        },
        header: true,
      });
    }
  };

  const removeUploadHandler = () => {
    setsecretKeyMode("");
    setInData((prev) => ({
      ...prev,
      public_key: initialData.public_key,
      secret_key: initialData.secret_key,
    }));
  };

  useEffectOnce(() => {
    if (!appid) return;
    setInData((prev) => ({
      ...prev,
      app_id: app.app_id,
      id: appid,
      app_name: app.name,
      email: user.email,
      merchant_id: user.company,
    }));
  });

  const cancelHandler = () => {
    if (loading) return;
    navigate(bgPathname, { replace: true });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(includeSwitchOn(inData))
      .unwrap()
      .then(cancelHandler)
      .catch(cancelHandler);
  };

  return (
    <CenterModal handleClose={cancelHandler} blurBg className="p-8">
      <form
        onSubmit={onSubmitHandler}
        className={tW(
          "flex flex-col items-center text-center",
          secretKeyMode ? "gap-y-10" : "gap-y-2"
        )}
        autoComplete="off"
      >
        <h3 className="text-2xl text-red-10">{PageDictionary.header.title}</h3>
        <p>{PageDictionary.header.sub.text}</p>

        {secretKeyMode !== "input" ? (
          <MediaUpload
            title={PageDictionary.form.uploadKeyLabel}
            className={{ container: "w-full", title: "mb-1 text-start" }}
            images={
              secretKeyMode === "upload" ? [{ id: 1, name: "secret" }] : []
            }
            onDrop={uploadHandler}
            onRemoveMedia={removeUploadHandler}
            accept={{
              "text/csv": [".csv"],
            }}
            name=""
            disabled={loading}
          />
        ) : null}
        {secretKeyMode === "" ? <p>Or</p> : null}
        {secretKeyMode !== "upload" ? (
          <>
            <CustomInput
              label={PageDictionary.form.publictKeyLabel}
              containerClassName="self-stretch flex flex-col"
              labelClassName="text-left mb-1"
              type="text"
              autoComplete="new-password"
              name="public_key"
              value={inData.public_key}
              onChange={changeHandler}
            />
            <CustomInput
              label={PageDictionary.form.secretKeyLabel}
              containerClassName="self-stretch flex flex-col"
              labelClassName="text-left mb-1"
              type="text"
              autoComplete="new-password"
              name="secret_key"
              value={inData.secret_key}
              onChange={changeHandler}
            />
          </>
        ) : null}
        <div className="flex items-center gap-x-4 self-end">
          <CustomButton type="button" variant onClick={cancelHandler}>
            {PageDictionary.form.cancelButton}
          </CustomButton>
          <CustomButton
            isloading={loading}
            type="submit"
            disabled={inData.public_key === "" || inData.public_key === ""}
          >
            {PageDictionary.form.submitButton}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
};
