import { AiOutlineSetting } from "react-icons/ai";
import { MdOutlinePreview, MdOutlineSpaceDashboard } from "react-icons/md";
import { BsBriefcase } from "react-icons/bs";
import { RiApps2Fill, RiShieldUserLine } from "react-icons/ri";
import routes from "../../routes";
import Language from "../language/en";

type Inavcomp = {
  title: string;
  link: string;
  icon: any;
  name: string;
};

const {
  sidenav: { nav: sidenav },
  application_layout: { nav: applicationnav },
  policy_layout: { nav: policynav },
  wallet_setting_layout: { nav: walletnav },
  compliance_layout: { nav: compliancenav },
  onboarding_layout: { nav: onboardnav },
  setting_layout: { nav: settingnav },
  utility_layout: { nav: utilitynav },
  include_layout: { nav: includenav },
} = Language.protected.Layout;

export const navComponents: Inavcomp[] = [
  {
    title: sidenav.dashboard,
    icon: <MdOutlineSpaceDashboard className="h-5 w-5" />,
    link: routes.protected.dashboard,
    name: "dashboard",
  },
  {
    title: sidenav.applications,
    icon: <RiApps2Fill className="h-5 w-5 rotate-45" />,
    link: routes.protected.applications.index,
    name: "applications",
  },
  {
    title: sidenav.compliance,
    icon: <BsBriefcase className="h-5 w-5" />,
    link: routes.protected.compliance.index,
    name: "compliance",
  },
  {
    title: sidenav.settings,
    icon: <AiOutlineSetting className="h-5 w-5" />,
    link: routes.protected.settings.index,
    name: "settings",
  },
  {
    title: sidenav.user,
    icon: <RiShieldUserLine className="h-5 w-5" />,
    link: routes.protected.user.index,
    name: "user",
  },
  {
    title: sidenav.onboarding,
    icon: <AiOutlineSetting className="h-5 w-5" />,
    link: routes.protected.onboarding.index,
    name: "onboarding",
  },
  {
    title: sidenav.preview,
    icon: <MdOutlinePreview className="h-5 w-5" />,
    link: routes.protected.preview_app,
    name: "onboarding",
  },
];

export const applicationNavLinks = [
  {
    title: applicationnav.wallet,
    link: routes.protected.applications.wallet,
    name: "wallet",
  },
  {
    title: applicationnav.outgoing_tx,
    link: routes.protected.applications.outgoingTx,
    name: "outgoing",
  },
  {
    title: applicationnav.incoming_tx,
    link: routes.protected.applications.incomingTx,
    name: "incoming",
  },
  {
    title: applicationnav.utility,
    link: routes.protected.applications.utility.history,
    name: "utility",
  },
  {
    title: applicationnav.users,
    link: routes.protected.applications.users,
    name: "users",
  },
  {
    title: applicationnav.policy,
    link: routes.protected.applications.policy,
    name: "policy",
  },
  {
    title: applicationnav.api_setting,
    link: routes.protected.applications.api_settings,
    name: "api",
  },
  {
    title: applicationnav.preferences,
    link: routes.protected.applications.checkout_settings,
    name: "checkout",
  },
  // {
  //   title: applicationnav.analytics,
  //   link: routes.protected.applications.analytics,
  //   name: "analytics",
  // },
  {
    title: applicationnav.include,
    link: routes.protected.applications.include.wallet,
    name: "include",
  },
];
export const policyNavLinks = [
  {
    title: policynav.risk_record,
    link: routes.protected.applications.policy.risk_record,
    name: "record",
  },
  {
    title: policynav.noti_config,
    link: routes.protected.applications.policy.notification_config,
    name: "notification",
  },
];
export const withdrawalSettingNavLinks = [
  {
    title: walletnav.crypto,
    link: routes.protected.applications.wallet.settings.withdrawal_details
      .crypto,
    name: "crypto",
  },
  {
    title: walletnav.bank,
    link: routes.protected.applications.wallet.settings.withdrawal_details.bank,
    name: "bank",
  },
];
export const utilityNavLinks = [
  {
    title: utilitynav.records,
    link: routes.protected.applications.utility.records,
    name: "records",
  },
  {
    title: utilitynav.history,
    link: routes.protected.applications.utility.history,
    name: "history",
  },
];
export const complianceNavLinks = [
  {
    name: compliancenav.bussiness_document,
    link: routes.protected.compliance.business_document.index,
  },
  {
    name: compliancenav.bussiness_shareholders,
    link: routes.protected.compliance.shareholders.index,
  },
  {
    name: compliancenav.bussiness_profile,
    link: routes.protected.compliance.bussiness_profile,
  },
];
export const onboardNavLinks = [
  {
    name: onboardnav.terms,
    link: routes.protected.onboarding.terms.index,
  },
  {
    name: onboardnav.bussiness_shareholders,
    link: routes.protected.onboarding.shareholders.index,
  },
  {
    name: onboardnav.bussiness_profile,
    link: routes.protected.onboarding.bussiness_profile,
  },
  {
    name: onboardnav.bussiness_document,
    link: routes.protected.onboarding.business_document.index,
  },
];
export const settingNavLinks = [
  {
    name: settingnav.security,
    link: routes.protected.settings.security,
  },
  {
    name: settingnav.business_profile,
    link: routes.protected.settings.business_profile,
  },
  {
    name: settingnav.tx_country,
    link: routes.protected.settings.transaction_country,
  },
  // {
  //   name: settingnav.activity_log,
  //   link: routes.protected.settings.activity_log,
  // },
];

export const includeNavLinks = [
  {
    name: includenav.wallet,
    link: routes.protected.applications.include.wallet,
  },
  {
    name: includenav.url,
    link: routes.protected.applications.include.url,
  },
  {
    name: includenav.pricing,
    link: routes.protected.applications.include.pricing,
  },
  {
    name: includenav.config,
    link: routes.protected.applications.include.config,
  },
];
