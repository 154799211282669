import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useEffectOnce } from "../../../../../../common/hooks";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import CustomButton from "../../../../../../common/components/CustomButton";
import ModalNavLinkWithdrawal from "../../../../components/ModalNavLinkWithdrawal";

import customToast from "../../../../../../common/components/CustomToast";
import routes from "../../../../../../common/routes";
import {
  FormatNumber,
  addCommas,
  removeCommas,
  removeNonNumeric,
  toCurrency,
} from "../../../../../../common/utils/helpers";
import { iHandleChange, iHandleSubmit } from "../../../../types";
import Language from "../../../../../../common/utils/language/en";

const { WithdrawlForm: PageDictionary } =
  Language.protected.walletModals.withdrawal;

const initialstate = {
  amount: "",
};

function WithdrawalForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const applocation = useLocation().state;
  const location = applocation?.background;
  const prev_amount = applocation?.amount;
  const [inData, setInData] = useState(initialstate);
  const is_bank = useAppSelector(
    (state) => state.app.allApps.data.find((app) => app._id === appid)?.is_bank
  );
  const {
    withdrawal: {
      data: { bank_account },
    },
    exchange_rate: { data: exchange_rate },
  } = useAppSelector((state) => state.wallet);

  const inData_amount = removeCommas(inData.amount);

  const rate = exchange_rate?.rate
    ? FormatNumber(
        parseInt(inData_amount ? inData_amount : "0") * exchange_rate?.rate
      )
    : "0.00";
  const fields = bank_account.find((account) => account.is_active === true);

  useEffectOnce(() => {
    if (prev_amount) {
      setInData((prev) => ({ ...prev, amount: prev_amount }));
    }
  });

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;
    setInData((prev) => ({
      ...prev,
      [name]: addCommas(removeNonNumeric(value)),
    }));
  };

  const backdropHandler = () => {
    navigate(location.pathname);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid) return;

    if (!fields?._id) {
      customToast.error(PageDictionary.error.no_active);
      backdropHandler();
      return;
    }
    if (bank_account.length === 0) {
      customToast.error(PageDictionary.error.no_account);
      backdropHandler();
      return;
    }

    let path = "";

    path =
      routes.protected.applications.wallet.withdrawl.authorize_bank.link(appid);

    navigate(path, {
      state: { background: location, amount: inData.amount },
    });
  };

  return (
    <SideModal
      onclickBackDrop={backdropHandler}
      className="p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <ModalTitleHeader
        title={PageDictionary.title}
        subtitle={PageDictionary.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <div>
          <label
            className="mb-2 inline-block text-base font-medium text-base-20"
            htmlFor="balance"
          >
            <span className="">{PageDictionary.form.amount_label}</span>
          </label>
          <div className="relative">
            <span className="absolute left-5 top-1/2 -translate-y-1/2 text-2xl font-medium text-gray-450">
              {toCurrency(0).split("0.00")}
            </span>
            <input
              type="text"
              id="balance"
              name="amount"
              className="h-[3.75rem] w-full rounded-xl border border-blue-50 bg-inputbg pt-3 pb-3 pl-11 text-black placeholder:text-base placeholder:text-gray-600"
              value={inData.amount}
              onChange={changeHandler}
            />
            <p className="absolute right-5 text-sm">
              {PageDictionary.form.amount_local_label}
              <span className="uppercase text-green-10">{`${exchange_rate?.from} ${rate}`}</span>
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-y-7">
          <p>{PageDictionary.form.destination}</p>
          <ModalNavLinkWithdrawal is_bank={is_bank} amount={inData.amount} />
        </div>
        <CustomButton
          disabled={inData.amount === ""}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default WithdrawalForm;
