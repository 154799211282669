import React from "react";
import SideNav from "../SideNav";
import { Outlet } from "react-router-dom";

type Props = {
  isPublic?: boolean;
};

function AppLayout({ isPublic }: Props) {
  return (
    <div className="bg-slate-50">
      <div className="relative flex h-screen bg-white design_screen:mx-auto  design_screen:max-w-screen-design_screen">
        <SideNav
          isPublic={isPublic}
          className="absolute top-0 left-0 bottom-0 bg-white"
        />
        <div className="absolute top-0 right-0 bottom-0 left-16 md:left-72 h-full flex-1 overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AppLayout;
