import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import { IupdatePassword, Itoggle2fa } from "../types";

export const updatePassword = createAsyncThunk(
  "setting/updatePassword",
  async (req: IupdatePassword, { rejectWithValue }) => {
    return service
      .updatePassword(req)
      .then((res) => {
        customToast.success(res.message);
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const initiatePasswordUpdate = createAsyncThunk(
  "setting/initiatePasswordUpdate",
  async (_, { fulfillWithValue }) => {
    return service
      .initiatePasswordUpdate()
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.message).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
      });
  }
);
export const resendPasswordUpdate = createAsyncThunk(
  "setting/resendPasswordUpdate",
  async () => {
    return service
      .resendPasswordUpdate()
      .then((res) => {
        customToast.success(res.message);
      })
      .catch((err) => {
        customToast.error(err.message);
      });
  }
);
export const getActivityLog = createAsyncThunk(
  "setting/getActivityLog",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getActivityLog()
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err).payload;
      });
  }
);

export const getUserInfo = createAsyncThunk(
  "setting/getUserInfo",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getUserInfo()
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const updateUserProfile = createAsyncThunk(
  "setting/updateUserProfile",
  async (data: Itoggle2fa, { fulfillWithValue, rejectWithValue }) => {
    return service
      .updateUserProfile(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
