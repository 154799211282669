import React, { useRef } from "react";
import { BsCheckAll } from "react-icons/bs";
import { CopyIcon } from "../../../static/images/icons";
import { useCopyToClipboard } from "../../hooks";
import { tW } from "../../utils/helpers";

type Props = {
  walletAddress: string;
};

const instructions = [
  "Send only USDT to this deposit address.",
  "Ensure the network is TRON (TRC-20).",
  "Do not send other coin to this address.",
];

function ClipboardCopy({ walletAddress }: Props) {
  const addressRef = useRef<HTMLHeadingElement | null>(null);
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  const handleCopyClick = () => {
    copyToClipboard(walletAddress);
  };

  return (
    <>
      <div className="flex items-center gap-x-6">
        <h4 className="text-xl font-bold text-gray-410" ref={addressRef}>
          {walletAddress}
        </h4>
        <button
          type="button"
          className={tW(
            "text-90 flex items-center gap-x-2",
            isCopied ? "animate-pulse text-green-10" : "text-gray-30"
          )}
          onClick={handleCopyClick}
        >
          {isCopied ? (
            <BsCheckAll className="text-2xl" />
          ) : (
            <span>
              <CopyIcon scale={0.75} />
            </span>
          )}
          <span>{isCopied ? "Address Copied!" : "Copy Address"}</span>
        </button>
      </div>
      <p className="pt-4 text-sm text-base-40">
        Network - <span className="font-bold text-gray-410">TRC-20</span>
      </p>
      <ul className="mt-5 list-inside list-disc text-base text-red-20">
        {instructions.map((itm) => (
          <li key={itm}>{itm}</li>
        ))}
      </ul>
    </>
  );
}

export default ClipboardCopy;
