import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeAuthorizedRequestWithFormData,
  makeAuthorizedRequestDownload,
} from "../../../common/service/request";
import { IupdateAccount } from "../../wallet/types";
import * as tp from "../types";

const service = {
  async getAllApps(): Promise<tp.APIResponseModel<tp.IappRes[]>> {
    const { method, url } = ENDPOINTS.application.list_apps;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async addNewApp(data: any): Promise<tp.APIResponseModel<tp.IappRes>> {
    const { method, url } = ENDPOINTS.application.create_app;
    return makeAuthorizedRequestWithFormData(method, url, data);
  },
  async updateExistingApp(
    req: tp.Iupdateapp
  ): Promise<tp.APIResponseModel<tp.IappRes>> {
    const { method, url: makeUrl } = ENDPOINTS.application.update_apps;
    const { appid, data } = req;
    const tempKeys = Object.keys(req.data);
    const url = makeUrl(appid);

    if (
      tempKeys.includes("parent_company_logo") ||
      tempKeys.includes("logo") ||
      tempKeys.includes("include_data") ||
      tempKeys.includes("checkout_logo") /* ||
      tempKeys.includes("card_image") */
    ) {
      return makeAuthorizedRequestWithFormData(method, url, data);
    }
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  // async downloadApiKey(req: IApiShow): Promise<any> {
  //   const { method, url: makeUrl } = ENDPOINTS.application.download_api_keys;
  //   const url = makeUrl(req.appid);
  //   return makeAuthorizedRequestDownload(method, url, req.data);
  // },
  async regenerateApiKey(req: tp.IApiShow): Promise<any> {
    const { method, url: makeUrl } = ENDPOINTS.application.regenerate_api_keys;
    const url = makeUrl(req.appid);
    return makeAuthorizedRequestDownload(method, url, req.data);
  },
  async retrieveApiKey(req: tp.IApiShow): Promise<tp.APIResponseSuccessModel> {
    const { method, url: makeUrl } = ENDPOINTS.application.retrieve_api_keys;
    const url = makeUrl(req.appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },
  async golive(req: tp.IgoLive): Promise<tp.APIResponseSuccessModel> {
    const { method, url: makeUrl } = ENDPOINTS.application.go_live;
    const url = makeUrl(req.appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },

  async listAppUsers(
    id: string,
    page: number,
    search?: string
  ): Promise<tp.APIResponseModel<tp.IappUser[]>> {
    const { method, url: makeUrl } = ENDPOINTS.app_user.list_app_users;
    const url = makeUrl(id, page, search);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async retriveAppUser(
    id: string,
    customerid: string
  ): Promise<tp.APIResponseSuccessModel> {
    const { method, url: makeUrl } = ENDPOINTS.app_user.retrive_app_user;
    const url = makeUrl(id, customerid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateAppUserStatus(
    appid: string,
    userid: string,
    data: IupdateAccount
  ): Promise<tp.APIResponseModel<tp.IappUser>> {
    const { method, url: makeUrl } = ENDPOINTS.app_user.update_app_user_status;
    const url = makeUrl(appid, userid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async listTransactions(
    req: tp.IlistTransaction
  ): Promise<tp.APIResponseModel<tp.ItransactionItem[]>> {
    const { appid, type, page, size, search } = req;
    const { method, url: makeUrl } = ENDPOINTS.wallet.list_transactions;
    const url = makeUrl(appid, page, size, type, search);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getTransaction(
    req: tp.ItxnBioData
  ): Promise<tp.APIResponseModel<tp.ItransactionItem>> {
    const { appid, txnid } = req;
    const { method, url: makeUrl } = ENDPOINTS.wallet.get_transaction;
    const url = makeUrl(appid, txnid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getAppTransaction(
    req: tp.ItxnBioData
  ): Promise<tp.APIResponseModel<tp.ItransactionItem>> {
    const { appid, txnid, type } = req;
    const { method, url: makeUrl } = ENDPOINTS.wallet.get_app_transaction;
    const url = makeUrl(appid, txnid, type);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async createReportTransaction(
    req: tp.IcreateReportTxnThunk
  ): Promise<tp.APIResponseModel<tp.IreportedTransaction>> {
    const { appid, data, txnid } = req;
    const { method, url: makeUrl } = ENDPOINTS.wallet.report_transaction.create;
    const url = makeUrl(appid, txnid);
    return makeAuthorizedRequestWithFormData(method, url, data);
  },
  async getReportedTransactionList(
    req: tp.ItxnBioData
  ): Promise<tp.APIResponseModel<tp.IreportedTransaction>> {
    const { appid, txnid } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.report_transaction.list_report;
    const url = makeUrl(appid, txnid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getSingleReportedTransaction(
    req: tp.IsingleReportTxnThunk
  ): Promise<tp.APIResponseModel<tp.IreportedTransaction>> {
    const { appid, txnid, rid } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.report_transaction.list_single_report;
    const url = makeUrl(appid, txnid, rid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateSingleReportedTransaction(
    req: tp.IupdatesingleReportTxnThunk
  ): Promise<tp.APIResponseModel<tp.IreportedTransaction>> {
    const { appid, txnid, rid, data } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.report_transaction.update_report_status;
    const url = makeUrl(appid, txnid, rid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async createPolicy(
    req: tp.IcreatePolicy
  ): Promise<tp.APIResponseModel<tp.IappPolicy>> {
    const { merchant_app: appid } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.create_policy;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req);
  },
  async listPolicies(
    appid: string
  ): Promise<tp.APIResponseModel<tp.IappPolicy[]>> {
    const { method, url: makeUrl } = ENDPOINTS.policy.list_policies;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updatePolicy(req: any): Promise<tp.APIResponseModel<tp.IappPolicy>> {
    const { merchant_app: appid, policyid } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.update_policy;
    const url = makeUrl(appid, policyid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, req);
  },
  async retrivePolicy(req: any): Promise<tp.APIResponseSuccessModel> {
    const { appid, policyid } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.retrive_policy;
    const url = makeUrl(appid, policyid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async createUserCategory(
    req: tp.IcreateUserCategory
  ): Promise<tp.APIResponseSuccessModel> {
    const { appid, data } = req;

    const { method, url: makeUrl } = ENDPOINTS.policy.create_category;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async listUserCategories(
    appid: string
  ): Promise<tp.APIResponseModel<tp.IappPolicyCategory[]>> {
    const { method, url: makeUrl } = ENDPOINTS.policy.list_categories;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateUserCategory(
    req: tp.IupdateUserCategory
  ): Promise<tp.APIResponseSuccessModel> {
    const { appid, catid, data } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.update_category;
    const url = makeUrl(appid, catid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retriveUserCategory(req: any): Promise<tp.APIResponseSuccessModel> {
    const { appid, policyid } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.retrive_category;
    const url = makeUrl(appid, policyid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listRiskRecords(
    req: tp.IriskRecord
  ): Promise<tp.APIResponseModel<tp.IriskPolicy[]>> {
    const { appid, pid, query } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.list_risk_records;
    const url = makeUrl(appid, pid, query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async addNotificationConfig(
    req: Omit<tp.InotificationConfigThunk, "navigateTo">
  ): Promise<tp.APIResponseModel<tp.Inotification>> {
    const { appid, pid, data } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.notification_config.add;
    const url = makeUrl(appid, pid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async listNotificationConfig(
    req: Omit<tp.InotificationConfig, "nid">
  ): Promise<tp.APIResponseModel<tp.Inotification[]>> {
    const { appid, pid } = req;
    const { method, url: makeUrl } = ENDPOINTS.policy.notification_config.list;
    const url = makeUrl(appid, pid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateNotificationConfig(
    req: tp.InotificationConfigUpdate
  ): Promise<tp.APIResponseModel<tp.Inotification>> {
    const { appid, pid, nid, data } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.policy.notification_config.update;
    const url = makeUrl(appid, pid, nid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async retrieveNotificationConfig(
    req: tp.InotificationConfig
  ): Promise<tp.APIResponseModel<tp.Inotification>> {
    const { appid, pid, nid } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.policy.notification_config.retrieve;
    const url = makeUrl(appid, pid, nid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async includeSwitchOn(
    data: tp.IincludeSwitchOn
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.application.include_switch_on;
    const url = makeUrl(data?.id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async includeSwitchOff(
    data: tp.IincludeSwitchOff
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.application.include_switch_off;
    const url = makeUrl(data?.id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async includeAddCustomDomain(
    data: tp.IAddCustomDomainData
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.application.add_custom_domain;
    const url = makeUrl(data.appid);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async includeVerifyCustomDomain(
    data: tp.IVerifyCustomDomainData
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.application.verify_domain;
    const url = makeUrl(data.appid);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async includeCheckDomainAvailability(
    data: tp.IAddCustomDomainData
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } =
      ENDPOINTS.application.check_domain_availability;
    const url = makeUrl(data.appid);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async removeCustomDomain(
    data: tp.IVerifyCustomDomainData
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url: makeUrl } = ENDPOINTS.application.remove_custom_domain;
    const url = makeUrl(data.appid);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
