import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../../../common/hooks";
import CustomInput from "../../../../common/components/CustomInput";
import * as DD from "../../../../common/components/CustomDropDown";
import { toast } from "../../../../common/components/CustomToast";
import { walletActions } from "../../../wallet/store/slice";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";
import { getDashboardInfo } from "../../../dashboard/store/thunk";
import { listAppUsers, listTransactions } from "../../store/thunk";
import {
  getWallet,
  listAppTransactions,
  listThresholds,
} from "../../../wallet/store/thunk";
import {
  fetchLocalData,
  removeGolivePrompt,
} from "../../../../common/service/storage";
import routes from "../../../../common/routes";
import { ArrowBack } from "../../../../static/images/icons";
import { tW } from "../../../../common/utils/helpers";
import { iHandleChange, iHandleSubmit } from "../../types";

type Props = {
  path?: string;
  layoutRef: React.MutableRefObject<HTMLElement | null>;
};

function AppSelector({ path, layoutRef }: Props) {
  const { appid } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useAppSelector((state) => state.pagination.filter);
  const debouceValue = useDebounce(search);
  const { pathname } = useLocation();
  const applist = useAppSelector((state) =>
    state.app.allApps.data
      .filter((app) => appid !== app._id)
      .map((app) => ({
        _id: app?._id,
        name: app?.name,
        mode: app?.mode,
      }))
  );
  const selected = useAppSelector((state) =>
    state.app.allApps.data.find((app) => app._id === appid)
  );

  useEffect(() => {
    if (!appid || debouceValue !== "") return;
    if (pathname.includes("users")) {
      dispatch(listAppUsers({ appid: appid, page: 1, search: debouceValue }));
    }
    if (pathname.includes("incoming-settlements")) {
      dispatch(
        listTransactions({
          appid,
          type: "CR",
          search: debouceValue,
          page: 1,
          size: 10,
        })
      );
    }
    if (pathname.includes("outgoing-transactions")) {
      dispatch(
        listTransactions({
          appid,
          type: "DR",
          page: 1,
          size: 10,
          search: debouceValue,
        })
      );
    }
  }, [debouceValue]); //eslint-disable-line

  useEffect(() => {
    if (!appid || debouceValue === "") return;
    if (pathname.includes("users")) {
      dispatch(listAppUsers({ appid: appid, page: 1, search: debouceValue }));
    }
    if (pathname.includes("incoming-settlements")) {
      dispatch(
        listTransactions({
          appid,
          type: "CR",
          search: debouceValue,
          page: 1,
          size: 10,
        })
      );
    }
    if (pathname.includes("outgoing-transactions")) {
      dispatch(
        listTransactions({
          appid,
          type: "DR",
          page: 1,
          size: 10,
          search: debouceValue,
        })
      );
    }
  }, [debouceValue]); //eslint-disable-line

  const changeAppHandler = (id: string, mode: string) => {
    if (appid === id) return;
    if (toast.isActive("TEST-MODE") && mode === "LIVE") {
      toast.dismiss("TEST-MODE");
    }
    const path = routes.protected.applications.wallet.link(id);
    dispatch(walletActions.resetWallet());
    navigate(path);

    dispatch(getWallet(id));

    dispatch(listThresholds(id));

    dispatch(listAppTransactions({ appid: id }));
  };

  const backHandler = () => {
    if (path) {
      navigate(path);
      dispatch(getDashboardInfo());
    }
    if (!path) {
      navigate(-1);
    }
    if (!pathname.includes("/wallet/settings/")) {
      dispatch(walletActions.resetWallet());
    }
    if (toast.isActive("TEST-MODE")) {
      toast.dismiss("TEST-MODE");
    }
    const { is_live_mode_ignored } = fetchLocalData();
    if (is_live_mode_ignored) {
      removeGolivePrompt();
    }
  };

  const handleChange: iHandleChange = (e) => {
    const { value, name } = e.target;
    dispatch(paginationActions.addQueryParams({ key: name, value }));
  };

  const searchSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <header ref={layoutRef} className="flex items-center justify-between gap-x-2">
      <form
        className="flex items-center gap-x-2 md:gap-x-5"
        onSubmit={(e) => e.preventDefault()}
      >
        <button type="button" onClick={backHandler}>
          <span className="flex h-8 w-8">
            <ArrowBack />
          </span>
        </button>

        <DD.DropdownMenu>
          <DD.DropdownMenuTrigger
            disabled={applist.length === 0}
            className="border border-red-10"
            asChild
            data-cy="cy-dropdown-trigger"
          >
            <button className="min-w-[12rem] cursor-pointer appearance-none rounded-xl border border-gray-340  bg-arrowdown bg-[90%] bg-no-repeat py-3 pl-6 pr-11 text-base font-bold text-gray-60">
              {selected?.name}
            </button>
          </DD.DropdownMenuTrigger>
          <DD.DropDownMenuContent className="text-blue_gray-10 z-10 flex w-48 cursor-pointer flex-col rounded-lg bg-white font-bold shadow-pool_card">
            {applist.map((app) => (
              <DD.DropDownItem key={app?._id} className="group w-full">
                <button
                  onClick={() => changeAppHandler(app?._id, app?.mode)}
                  className={tW(
                    "flex w-full items-center gap-x-2 px-4 py-2 text-left text-sm text-black hover:text-cyan-10 group-first:pt-3.5 group-last:pb-7 group-only:py-4",
                    selected?._id === app?._id ? "text-cyan-10" : ""
                  )}
                >
                  <span>{app?.name}</span>
                </button>
              </DD.DropDownItem>
            ))}
          </DD.DropDownMenuContent>
        </DD.DropdownMenu>
      </form>
      {(pathname.includes("outgoing-transactions") ||
        pathname.includes("incoming-settlements") ||
        pathname.includes("users")) && (
        <form className="relative max-w-[24rem]" onSubmit={searchSubmitHandler}>
          <CustomInput
            labelClassName="hidden"
            type="search"
            className="h-12 py-1 pl-11 outline-none"
            placeholder="Search"
            value={search}
            name="search"
            onChange={handleChange}
          />
          <button className="absolute top-1/2 left-4 -translate-y-1/2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </form>
      )}
    </header>
  );
}

export default AppSelector;
