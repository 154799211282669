import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../common/hooks";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import CustomInput from "../../../../../../common/components/CustomInput";
import CustomButton from "../../../../../../common/components/CustomButton";
import ModalNavLinks from "../../../../components/ModalNavLinkFunding";
import { walletActions } from "../../../../store/slice";
import { FormatNumber } from "../../../../../../common/utils/helpers";
import { iHandleChange } from "../../../../types";
import {
  addCommas,
  removeCommas,
  removeNonNumeric,
} from "../../../../../../common/utils/helpers";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";

const { FundWalletForm: PageDictionary } =
  Language.protected.walletModals.funding;

const initialstate = {
  message: "",
  type: "funding_amount",
};

function FundWalletForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state?.background;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const { isEstablishingConnection, isConnected } = useAppSelector(
    (state) => state.wallet.socket
  );
  const is_bank = useAppSelector(
    (state) => state.app.allApps.data.find((app) => app._id === appid)?.is_bank
  );

  const exchange_rate = useAppSelector(
    (state) => state.wallet.exchange_rate.data
  );

  const rate =
    exchange_rate?.rate &&
    FormatNumber(
      parseInt(
        removeCommas(inData.message) ? removeCommas(inData.message) : "0"
      ) / exchange_rate?.rate,
      true
    );

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      message: addCommas(removeNonNumeric(value)),
    }));
  }, []);

  const backDrop = () => {
    if (!appid || isEstablishingConnection) return;
    let path = routes.protected.applications.wallet.link(appid);
    navigate(path);
  };

  // start socket for wallet funding
  useEffect(() => {
    if (!appid) return;
    let path = "";

    if (isConnected === true) {
      path =
        routes.protected.applications.wallet.fund.payment_processing.link(
          appid
        );
      dispatch(
        walletActions.submitMessage({
          content: { ...inData, message: removeCommas(inData.message) },
        })
      );
      navigate(path, {
        state: { background: location, amount: inData.message },
      });
    }
    if (!exchange_rate?.rate) {
      backDrop();
    }
  }, [isEstablishingConnection, isConnected]); // eslint-disable-line

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid) return;

    dispatch(
      walletActions.startConnecting({ app_id: appid, txn_reference: null })
    );
  };

  return (
    <SideModal onclickBackDrop={backDrop} className="p-8 md:pt-22 md:pl-15 md:pr-28">
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <ModalNavLinks is_bank={is_bank} />
        <div className="relative">
          <span className="absolute bottom-5 left-4 z-[2] uppercase">
            {exchange_rate?.from}
          </span>
          <CustomInput
            label={PageDictionary.form.amount_label}
            className="pl-[4.25rem]"
            value={inData.message}
            onChange={changeHandler}
          />
          <p className="absolute right-5">
            {PageDictionary.form.amount_dollar}{" "}
            <span className="text-red-10">{rate}</span>
          </p>
        </div>

        <p className="text-sm text-gray-400">{PageDictionary.form.warn_text}</p>

        <CustomButton
          disabled={inData.message === ""}
          isloading={isEstablishingConnection}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundWalletForm;
