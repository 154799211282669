import { NavLink, useLocation } from "react-router-dom";
import scrollbarStyles from "../../../../../static/styles/scrollbar.module.css";
import { DynamicProps } from "../types";
import { tW } from "../../../../utils/helpers";

export function DynamicHorizontalNav({ navlinks, handleClick }: DynamicProps) {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <ul
      className={tW(
        "flex flex-grow items-end gap-x-6 overflow-x-auto pt-10 pb-9",
        scrollbarStyles.no_scrollbar
      )}
    >
      {navlinks.map(({ link, name, title }) => (
        <li
          key={name}
          className="whitespace-nowrap text-[1.125rem] leading-6 first:pr-5 first:pl-0 last:pr-0 last:pl-5"
        >
          <NavLink
            onClick={(e) => handleClick(e, link, name)}
            to={link.index}
            className={tW(
              "border-b-2 px-2 pb-2 text-base",
              pathname.includes(name)
                ? "border-black text-[1.125rem] font-bold text-black"
                : "border-transparent font-normal text-gray-360"
            )}
          >
            {title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
