import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeAuthorizedRequestWithFormData,
} from "../../../common/service/request";
import {
  APIResponseModel,
  IaddBankAccount,
  IappWallet,
  IbankAccount,
  IbankAccountType,
  IbankWithdrawal,
  IbankWithdrawalResponse,
  IexchangeRate,
  IthresholdThunk,
  IupdatethresholdThunk,
  IuploadEvidence,
  IwithdrawThreshold,
  IwithrawCrypto,
  // IWtransactions,
} from "../types";

const service = {
  async getWallet(id: string): Promise<APIResponseModel<IappWallet>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet.get_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async uploadPaymentEvidence(
    req: IuploadEvidence
  ): Promise<APIResponseModel<any>> {
    const { appid, data } = req;
    const { method, url: makeUrl } = ENDPOINTS.wallet.upload_payment_evidence;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithFormData(method, url, data);
  },
  async getExchangeRate(id: string): Promise<APIResponseModel<IexchangeRate>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet.get_exchange_rate;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listAppTransactions(
    id: string,
    type?: string
  ): Promise<APIResponseModel<any[]>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet.list_app_transactions;
    const url = makeUrl(id, type);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },

  async addWithdrawalThreshold(
    req: IthresholdThunk
  ): Promise<APIResponseModel<IwithdrawThreshold>> {
    const { appid, data } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.withdrawal_threshold.add_threshold;
    const url = makeUrl(appid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async listWithdrawalThresholds(
    id: string
  ): Promise<APIResponseModel<IwithdrawThreshold[]>> {
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.withdrawal_threshold.list_thresholds;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateWithdrawalThreshold(
    req: IupdatethresholdThunk
  ): Promise<APIResponseModel<IwithdrawThreshold>> {
    const { appid, threshid, data } = req;
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.withdrawal_threshold.update_threshold;
    const url = makeUrl(appid, threshid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async deleteWithdrawalThreshold(
    id: string,
    thresid: string
  ): Promise<APIResponseModel<IwithdrawThreshold>> {
    const { method, url: makeUrl } =
      ENDPOINTS.wallet.withdrawal_threshold.delete_threshold;
    const url = makeUrl(id, thresid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listBankAccountTypes(
    id: string
  ): Promise<APIResponseModel<IbankAccountType[]>> {
    const { method, url: makeUrl } =
      ENDPOINTS.withdrawal.list_bank_account_types;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listDeactivatedAccount(
    id: string
  ): Promise<APIResponseModel<IbankAccount[]>> {
    const { method, url: makeUrl } =
      ENDPOINTS.withdrawal.list_deactivated_accounts;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async addBankAccount(
    id: string,
    data: IaddBankAccount
  ): Promise<APIResponseModel<IbankAccount>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.add_bank_account;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getBankAccount(id: string): Promise<APIResponseModel<IbankAccount>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.get_bank_account;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async updateBankAccount(
    id: string,
    bankid: string,
    data: any
  ): Promise<APIResponseModel<IbankAccount>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.update_bank_account;
    const url = makeUrl(id, bankid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async bankWithdrawal(
    id: string,
    data: IbankWithdrawal
  ): Promise<APIResponseModel<IbankWithdrawalResponse>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.bank_withdrawal;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async addCryptoAddress(
    id: string,
    data: { address: string }
  ): Promise<APIResponseModel<IwithrawCrypto>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.crypto.add_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getCryptoAddress(
    id: string
  ): Promise<APIResponseModel<IwithrawCrypto>> {
    const { method, url: makeUrl } = ENDPOINTS.withdrawal.crypto.get_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async deactivateCryptoAddress(
    id: string,
    wid: string
  ): Promise<APIResponseModel<{}>> {
    const { method, url: makeUrl } =
      ENDPOINTS.withdrawal.crypto.deactivate_wallet;
    const url = makeUrl(id, wid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
