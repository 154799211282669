import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import { useAppSelector, usePageTitle } from "../../../../common/hooks";
import { FixedHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
import routes from "../../../../common/routes";
import { complianceNavLinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";

const PageDictionary = Language.protected.Layout.compliance_layout;

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loading: shareholder_loading_state } = useAppSelector(
    (state) => state.compliance.shareholders
  );

  usePageTitle(PageDictionary.page_title);

  const clickHandler = () => {
    navigate(routes.protected.compliance.shareholders.add, {
      state: { background: location },
    });
  };

  return (
    <section className="flex h-screen flex-col overflow-y-scroll gap-y-4 md:gap-y-12 px-8 py-10 md:py-18 md:px-16">
      <header>
        <h3 className="select-none text-2826 font-semibold">
          {PageDictionary.page_title}
        </h3>
        <nav className="flex items-center justify-between pt-3">
          <HorizontalNav navlinks={complianceNavLinks} />
          {location.pathname ===
            routes.protected.compliance.shareholders.index &&
          shareholder_loading_state === false ? (
            <CustomButton
              disabled={shareholder_loading_state}
              onClick={clickHandler}
              className=" px-2.5 py-3 text-base leading-7"
            >
              {PageDictionary.add_shareholder}
            </CustomButton>
          ) : null}
        </nav>
      </header>
      <Outlet />
    </section>
  );
}

export default Layout;
