import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../common/components/ModalTitleHeader";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomInput from "../../../../../common/components/CustomInput";
import {
  Select,
  SelectItem,
} from "../../../../../common/components/CustomSelect";
import { createUserCategory, updateUserCategory } from "../../../store/thunk";
import { iHandleChange, iHandleSubmit } from "../../../types";
import { Policy, diffrenceHandler, initialstate } from "./helper";
import routes from "../../../../../common/routes";
import Language from "../../../../../common/utils/language/en";

const { create_user_category: PageDictionary } =
  Language.protected.ApplicationPolicyModal;

export function CreateCategory() {
  const { appid, catid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const pathname: string = state?.background?.pathname;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const {
    app_policies,
    app_policies_user_category: { loading, data },
  } = useAppSelector((state) => state.app);

  const credit_policies = app_policies.data.filter(
    (policy) => policy.type === "CREDIT"
  );
  const debit_policies = app_policies.data.filter(
    (policy) => policy.type === "DEBIT"
  );

  const [buttonText, setButtonText] = useState(
    PageDictionary.form_button.submit
  );

  const user_cat = data.find(
    (itm) => itm.merchant_app === appid && itm._id === catid
  );
  const { data: diffData, count: diffCount } = diffrenceHandler(
    inData,
    user_cat
  );

  const disableUpdate =
    diffCount === 0 && PageDictionary.form_button.update === buttonText;

  useEffectOnce(() => {
    if (!appid || !catid || !user_cat) return;
    setButtonText(PageDictionary.form_button.update);
    setInData((prev) => ({
      ...prev,
      name: user_cat.name,
      credit_policy: {
        name: user_cat.credit_policy?.name,
        id: user_cat.credit_policy?._id,
      },
      debit_policy: {
        name: user_cat.debit_policy?.name,
        id: user_cat.debit_policy?._id,
      },
    }));
  });

  const backDropHandler = () => {
    if (!appid || loading) return;
    const path = routes.protected.applications.policy.link(appid);
    navigate(path, { replace: true });
  };

  // const changeHandler: iHandleChange = (e) => {
  //   const { name, value } = e.target;
  //   setInData((prev) => ({ ...prev, [name]: value }));
  // };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const selectHandler = (value: string, type: Policy) => {
    const val = JSON.parse(value);
    setInData((prev) => ({
      ...prev,
      [type]: {
        name: val.name,
        id: val._id,
      },
    }));
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid) return;
    const temp = {
      name: inData.name,
      credit_policy_id: inData.credit_policy.id,
      debit_policy_id: inData.debit_policy.id,
    };

    if (Object.values(temp).includes("")) return;

    const navigateAction = () => navigate(pathname, { replace: true });

    if (buttonText === PageDictionary.form_button.submit) {
      dispatch(
        createUserCategory({
          appid,
          data: temp,
        })
      )
        .unwrap()
        .finally(navigateAction);
    }
    if (!user_cat || !catid) return;
    if (buttonText === PageDictionary.form_button.update) {
      dispatch(
        updateUserCategory({
          data: diffData,
          appid,
          catid,
        })
      )
        .unwrap()
        .finally(navigateAction);
    }
  };
  return (
    <SideModal
      className="flex flex-col p-8 md:pt-22 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <ModalTitleHeader
        title={`${
          catid
            ? PageDictionary.header.title.update
            : PageDictionary.header.title.create
        }`}
        subtitle={`${
          catid
            ? PageDictionary.header.subtitle.update
            : PageDictionary.header.subtitle.create
        }`}
      />
      <form
        onSubmit={submitHandler}
        className="flex flex-1 flex-col  pt-9 pb-9"
      >
        <div className="flex flex-col gap-y-5">
          <CustomInput
            className="h-12 !py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.category_name}
            name="name"
            value={inData.name}
            onChange={changeHandler}
            disabled={loading}
          />

          <div className="flex flex-wrap items-center gap-x-8 gap-y-4">
            <div className="flex flex-col gap-y-4">
              <div className="rounded-10 bg-blue-80 py-2 text-center">
                {PageDictionary.form.incoming_policy_label}
              </div>
              <Select
                label=""
                labelClassName="mb-0 hidden"
                className="h-12"
                onChange={(e) => selectHandler(e, "credit_policy")}
                disabled={loading}
                value={inData.credit_policy.name}
                placeholder={PageDictionary.form.incoming_policy_placeholder}
              >
                {credit_policies.map((policy) => (
                  <SelectItem key={policy._id} value={JSON.stringify(policy)}>
                    <span>{policy.name}</span>
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="rounded-10 bg-brown-10 py-2 text-center">
                {PageDictionary.form.outgoing_policy_label}
              </div>

              <Select
                label=""
                labelClassName="mb-0 hidden"
                className="h-12"
                onChange={(e) => selectHandler(e, "debit_policy")}
                disabled={loading}
                value={inData.debit_policy.name}
                placeholder={PageDictionary.form.outgoing_policy_placeholder}
              >
                {debit_policies.map((policy) => (
                  <SelectItem key={policy._id} value={JSON.stringify(policy)}>
                    <span>{policy.name}</span>
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-end justify-end">
          <CustomButton
            disabled={Object.values(inData).includes("") || disableUpdate}
            isloading={loading}
            className="mt-4 self-start rounded-lg py-3 px-12 text-sm leading-6"
          >
            {buttonText}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}
