import { tW } from "../../utils/helpers";
import { Itablelayout } from "./type";

export const tableHeadStyle = (layout: Itablelayout) => {
  let template;
  switch (layout) {
    case "app":
      template =
        " sticky top-0 z-[1] border-b bg-white py-2.5 text-sm leading-6 text-gray-330 ";
      break;
    case "comp":
      template =
        " sticky top-0 z-[1] border-b bg-white py-2.5 px-9 text-sm leading-6 text-gray-330 ";
      break;
    case "wal-set":
      template =
        "pb-3 px-8 text-base leading-none text-black font-medium capitalize";
      break;

    default:
      template =
        "sticky top-0 z-[1]  bg-white pb-0.5 text-sm leading-6 text-gray-330";
      break;
  }
  return template;
};

export const tableDataStyle = (
  layout: Itablelayout,
  status?: string,
  active?: boolean
) => {
  let template;
  switch (layout) {
    case "comp":
      template = tW(
        "flex-1 cursor-pointer border-y pt-4 pb-5 px-9 text-1417 font-medium text-gray-320 first:rounded-l-xl first:border-l first:pl-9 last:rounded-r-xl last:border-r last:pr-9",
        status?.toLowerCase() === "approved"
          ? "border-green-50 bg-green-60"
          : "border-gray-310"
      );
      break;
    case "wal-set":
      template = tW(
        "flex-1 cursor-pointer border-y pt-4 pb-5 px-9 text-1417 font-medium text-green-40 first:rounded-l-xl first:border-l first:pl-9 last:rounded-r-xl last:border-r last:pr-9",
        active ? "border-green-50 bg-green-30 " : ""
      );
      break;

    default:
      template =
        "flex-1 cursor-pointer border-y border-gray-310 px-4 text-1417 font-medium text-gray-320 first:rounded-l-xl first:border-l first:pl-4.5 last:rounded-r-xl last:border-r last:pr-4.5 group";
      break;
  }
  return template;
};
