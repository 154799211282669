import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../common/components/ModalTitleHeader";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomInput from "../../../../../common/components/CustomInput";
import CustomTextArea from "../../../../../common/components/CustomTextArea";
import routes from "../../../../../common/routes";
import { createPolicy, updatePolicy } from "../../../store/thunk";
import { tW, validationRules } from "../../../../../common/utils/helpers";
import { diffrenceHandler, initialerrorstate, initialstate } from "./helper";
import { iHandleBlur, iHandleChange, iHandleSubmit } from "../../../types";
import Language from "../../../../../common/utils/language/en";
import * as Slt from "../../../../../common/components/CustomSelect";

const { create_policy_form: PageDictionary } =
  Language.protected.ApplicationPolicyModal;

export function CreatePolicy() {
  const { appid, policyid } = useParams();
  const pathname = useLocation().state?.background?.pathname;
  const navigate = useNavigate();
  const [inData, setInData] = useState(initialstate);
  const [inError, setInError] = useState(initialerrorstate);
  const {
    app_policies: { data, loading },
  } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const policy = data.find((policy) => policy._id === policyid);
  const [buttonText, setButtonText] = useState(
    PageDictionary.form_submit.submit
  );

  const { count } = diffrenceHandler(inData, policy);
  const includes_error = Object.values(inError).includes(true);

  const updateDisable =
    count === 0 && buttonText === PageDictionary.form_submit.update;
  const submitDisable =
    buttonText === PageDictionary.form_submit.submit &&
    (Object.values(inError).includes(true) ||
      !(Object.values(inData).filter((itm) => itm !== "").length > 1));

  useEffectOnce(() => {
    if (!appid) return;
    if (!policy) {
      setInData((prev) => ({ ...prev, merchant_app: appid }));
    }
    if (policy) {
      setButtonText(PageDictionary.form_submit.update);
      (Object.keys(inData) as Array<keyof typeof inData>).forEach((key) => {
        setInData((prev) => ({
          ...prev,
          [key]: policy[key] === null ? "" : policy[key],
        }));
      });
    }
  });

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules.split("/").join(""));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const blurHandler: iHandleBlur = useCallback((e) => {
    const { name, value } = e.target;
    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules.split("/").join(""));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const fieldSetBlurHandler = (
    e: React.FocusEvent<HTMLFieldSetElement, Element>
  ) => {
    const { name } = e.target;
    if (inData.type === "") {
      setInError((prev) => ({ ...prev, [name]: true }));
    } else {
      setInError((prev) => ({ ...prev, [name]: false }));
    }
  };

  const selectHandler = (e: string) => {
    setInData((prev) => ({ ...prev, allowed_duration_btw_transaction: e }));
  };

  const backDropHandler = () => {
    if (!appid || loading) return;
    const path = routes.protected.applications.policy.link(appid);
    navigate(path, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!pathname || includes_error || loading) return;
    if (Object.values(inData).includes("")) {
      (Object.keys(inData) as Array<keyof typeof inData>).forEach((key) => {
        if (inData[key] === "") {
          setInError((prev) => ({ ...prev, [key]: true }));
        }
      });
      return;
    }

    if (policy) {
      const temp: any = {};
      (Object.keys(inData) as Array<keyof typeof inData>).forEach((key) => {
        if (inData[key] !== policy[key]) {
          temp[key] = inData[key];
        }
      });

      dispatch(
        updatePolicy({
          ...temp,
          merchant_app: appid,
          policyid,
        })
      )
        .unwrap()
        .finally(backDropHandler);
    }
    if (!policy) {
      dispatch(createPolicy(inData)).unwrap().finally(backDropHandler);
    }
  };
  return (
    <SideModal
      className="p-9 md:pt-22 md:pl-15 md:pr-28 md:pb-9"
      onclickBackDrop={backDropHandler}
    >
      <ModalTitleHeader
        title={
          buttonText === PageDictionary.form_submit.submit
            ? PageDictionary.header.title.create
            : PageDictionary.header.title.update
        }
        subtitle={
          buttonText === PageDictionary.form_submit.submit
            ? PageDictionary.header.subtitle.create
            : PageDictionary.header.subtitle.update
        }
      />
      <form onSubmit={submitHandler} className="flex flex-col gap-y-4.5 pt-9">
        <fieldset
          id="policy_type"
          className={tW(
            "rounded-xl border py-3.5  pl-0.5",
            inError.type ? "border-red-10" : "border-transparent"
          )}
          onBlur={fieldSetBlurHandler}
        >
          <legend className="inline-block bg-white pl-0.5 pr-4 text-base font-medium text-base-20">
            {PageDictionary.form.policy_type_fieldset}
          </legend>

          <div className="grid grid-cols-2 px-2">
            <CustomInput
              type="radio"
              label={PageDictionary.form.incoming_txn.label}
              name="type"
              value="CREDIT"
              onChange={changeHandler}
              tooltip={PageDictionary.form.incoming_txn.tooltip}
              disabled={loading || policyid !== undefined}
              checked={inData.type === "CREDIT"}
            />
            <CustomInput
              type="radio"
              label={PageDictionary.form.outgoing_txn.label}
              name="type"
              value="DEBIT"
              onChange={changeHandler}
              tooltip={PageDictionary.form.outgoing_txn.tooltip}
              disabled={loading || policyid !== undefined}
              checked={inData.type === "DEBIT"}
            />
          </div>
        </fieldset>

        <CustomInput
          className="h-11 py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          label={PageDictionary.form.policy_name}
          name="name"
          value={inData.name}
          onChange={changeHandler}
          disabled={loading}
          haserror={inError.name}
          rules={validationRules.string}
          onBlur={blurHandler}
        />
        <CustomTextArea
          label={PageDictionary.form.policy_desc}
          name="description"
          value={inData.description}
          onChange={changeHandler}
          cols={4}
          rows={3}
          disabled={loading}
          rules={validationRules.string}
          onBlur={blurHandler}
          haserror={inError.description}
        />

        <div className="grid grid-cols-2 items-end gap-y-4.5 gap-x-8">
          <CustomInput
            className="h-11 py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.total_txn_day.label}
            name="max_daily_txn_freq"
            value={inData.max_daily_txn_freq}
            onChange={changeHandler}
            onBlur={blurHandler}
            rules={validationRules.number}
            haserror={inError.max_daily_txn_freq}
            disabled={loading}
            tooltip={PageDictionary.form.total_txn_day.tooltip}
          />
          <CustomInput
            className="h-11 py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.max_threshold_day.label}
            name="max_daily_txn_amount"
            value={inData.max_daily_txn_amount}
            onChange={changeHandler}
            onBlur={blurHandler}
            haserror={inError.max_daily_txn_amount}
            iscurrency="$"
            rules={validationRules.number}
            disabled={loading}
            tooltip={PageDictionary.form.max_threshold_day.tooltip}
          />
          <CustomInput
            className="h-11 py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.max_threshold_time.label}
            name="max_transaction_amount"
            value={inData.max_transaction_amount}
            iscurrency="$"
            onChange={changeHandler}
            onBlur={blurHandler}
            haserror={inError.max_transaction_amount}
            rules={validationRules.number}
            disabled={loading}
            tooltip={PageDictionary.form.max_threshold_time.tooltip}
          />
          <Slt.Select
            label={PageDictionary.form.time_btw_txn.label}
            className="h-12 py-2 pl-4"
            onChange={selectHandler}
            disabled={loading}
            value={inData.allowed_duration_btw_transaction}
            placeholder={PageDictionary.form.time_btw_txn.placeholder}
            tooltip={PageDictionary.form.time_btw_txn.tooltip}
            onFocus={() =>
              setInError((prev) => ({
                ...prev,
                allowed_duration_btw_transaction:
                  inData.allowed_duration_btw_transaction === "",
              }))
            }
            hasError={inError.allowed_duration_btw_transaction}
          >
            {PageDictionary.form.time_btw_txn.options.map((itm) => (
              <Slt.SelectItem key={itm} value={JSON.stringify(itm)}>
                <span>{itm}s</span>
              </Slt.SelectItem>
            ))}
          </Slt.Select>
          <CustomInput
            name="quarantine_transaction_amount"
            className="h-11 py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.quarantine_txn_amount.label}
            disabled={loading}
            tooltip={PageDictionary.form.quarantine_txn_amount.tooltip}
            value={inData.quarantine_transaction_amount}
            iscurrency="$"
            onChange={changeHandler}
            onBlur={blurHandler}
            haserror={inError.quarantine_transaction_amount}
            rules={validationRules.number}
          />
          <CustomInput
            name="quarantine_transaction_freq"
            className="h-11 py-1.5"
            containerClassName=""
            labelClassName="mb-2.5"
            label={PageDictionary.form.quarantine_txn_freq.label}
            tooltip={PageDictionary.form.quarantine_txn_freq.tooltip}
            disabled={loading}
            value={inData.quarantine_transaction_freq}
            onChange={changeHandler}
            onBlur={blurHandler}
            haserror={inError.quarantine_transaction_freq}
            rules={validationRules.number}
          />
        </div>
        <CustomButton
          isloading={loading}
          disabled={submitDisable || updateDisable || includes_error}
          className="mt-4 self-start rounded-lg py-3 px-12 text-sm leading-6"
        >
          {buttonText}
        </CustomButton>
      </form>
    </SideModal>
  );
}
