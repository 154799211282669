import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { UtilitySlice } from "../types";
import * as tk from "./thunk";
import { logUserOut } from "../../auth/store/thunk";

const initialState: UtilitySlice = {
  records: {
    data: [],
    loading: false,
  },
  history: {
    data: [],
    loading: false,
  },
  utility_balance: {
    loading: false,
    data: null,
  },
  theshold: {
    loading: false,
    data: null,
  },
  exchange_rate: {
    data: null,
    loading: false,
  },
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    resetutility: (state) => {
      const keys = Object.keys(state) as Array<keyof UtilitySlice>;
      keys.forEach((key) => {
        switch (key) {
          case "utility_balance":
            state[key] = { ...initialState[key] };
            break;
          case "exchange_rate":
            state[key] = { ...initialState[key] };
            break;
          case "theshold":
            state[key] = { ...initialState[key] };
            break;
          case "history":
            state[key] = { ...initialState[key] };
            break;
          default:
            state[key] = { ...initialState[key] };
            break;
        }
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        state.history = { ...initialState.history };
        state.records = { ...initialState.records };
        state.utility_balance = { ...initialState.utility_balance };
        state.theshold = { ...initialState.theshold };
        state.exchange_rate = { ...initialState.exchange_rate };
      })
      .addCase(tk.getUtility.fulfilled, (state, { payload }) => {
        state.utility_balance.data = payload;
      })
      .addCase(tk.getExchangeRate.fulfilled, (state, { payload }) => {
        state.exchange_rate.data = payload;
      })
      .addCase(tk.getUtilityRecords.fulfilled, (state, { payload }) => {
        state.records.data = [...payload.data];
      })
      .addCase(tk.getUtilityHistory.fulfilled, (state, { payload }) => {
        state.history.data = [...payload.data];
      })
      .addCase(tk.getExchangeRate.pending, (state) => {
        state.exchange_rate.loading = true;
      })
      .addMatcher(
        isAnyOf(tk.getExchangeRate.rejected, tk.getExchangeRate.fulfilled),
        (state) => {
          state.exchange_rate.loading = initialState.exchange_rate.loading;
        }
      )
      .addMatcher(isAnyOf(tk.getUtilityHistory.pending), (state) => {
        state.history.loading = true;
      })
      .addMatcher(
        isAnyOf(tk.getUtilityHistory.fulfilled, tk.getUtilityHistory.rejected),
        (state) => {
          state.history.loading = initialState.history.loading;
        }
      )
      .addMatcher(isAnyOf(tk.getUtilityRecords.pending), (state) => {
        state.records.loading = true;
      })
      .addMatcher(
        isAnyOf(tk.getUtilityRecords.fulfilled, tk.getUtilityRecords.rejected),
        (state) => {
          state.records.loading = initialState.records.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getWithdrawalThreshold.fulfilled,
          tk.setWithdrawalThreshold.fulfilled
        ),
        (state, { payload }) => {
          state.theshold.data = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getWithdrawalThreshold.pending,
          tk.setWithdrawalThreshold.pending,
          tk.removeWithdrawalThreshold.pending
        ),
        (state) => {
          state.theshold.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getWithdrawalThreshold.fulfilled,
          tk.getWithdrawalThreshold.rejected,
          tk.setWithdrawalThreshold.fulfilled,
          tk.setWithdrawalThreshold.rejected,
          tk.removeWithdrawalThreshold.fulfilled,
          tk.removeWithdrawalThreshold.rejected
        ),
        (state) => {
          state.theshold.loading = initialState.theshold.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getUtility.pending,
          tk.fundUtilityWallet.pending,
          tk.withdrawUtilityWallet.pending
        ),
        (state) => {
          state.utility_balance.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.getUtility.fulfilled,
          tk.getUtility.rejected,
          tk.fundUtilityWallet.fulfilled,
          tk.fundUtilityWallet.rejected,
          tk.withdrawUtilityWallet.fulfilled,
          tk.withdrawUtilityWallet.rejected
        ),
        (state) => {
          state.utility_balance.loading = initialState.utility_balance.loading;
        }
      );
  },
});

export const utilityActions = utilitySlice.actions;

export default utilitySlice.reducer;
