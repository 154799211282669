import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import UploadBusinessDocument from "../../compliance/Modals/UploadBusinessDocument";
import ShareholderForm from "../../compliance/Modals/ShareholderForm";
import ConfirmShareholderAction from "../../compliance/Modals/ConfirmShareholderAction";
import AgreementInformation from "./AgreementInformation";
import Authorize from "./Authorize";

function OnboardingModal() {
  return (
    <Routes>
      <Route
        element={<AgreementInformation />}
        path={routes.protected.onboarding.terms.agreement}
      />
      <Route
        element={<Authorize />}
        path={routes.protected.onboarding.terms.authorize}
      />
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.onboarding.business_document.get_started}
      />
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.onboarding.business_document.update_doc.index}
      />
      <Route
        element={<ShareholderForm />}
        path={routes.protected.onboarding.shareholders.add}
      />
      <Route
        element={<ConfirmShareholderAction />}
        path={routes.protected.onboarding.shareholders.remove.index}
      />
    </Routes>
  );
}

export default OnboardingModal;
