import cuid from "cuid";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../common/hooks";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../../../common/components/ModalTitleHeader";
import CustomInput from "../../../../../../common/components/CustomInput";
import MediaUpload from "../../../../../applications/components/MediaUpload";
import CustomButton from "../../../../../../common/components/CustomButton";
import { uploadPaymentEvidence } from "../../../../store/thunk";
import { removeUnderScoreAddSpace } from "../../../../../../common/utils/helpers";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";

const { FundBankForm: PageDictionary } = Language.protected.walletModals.funding;

type iFileToUpload = {
  data: FileList;
  id: string;
  name: string;
};
type Istate = {
  txn_reference: string;
  image: iFileToUpload[];
};
type BankData = {
  account_name: string;
  account_number: string;
  bank_name: string;
  amount: string;
};
const initialstate = {
  txn_reference: "",
  image: [],
};
const removeAmount = (data?: BankData): Omit<BankData, "amount"> => {
  let temp: Omit<BankData, "amount"> = {
    account_name: "",
    account_number: "",
    bank_name: "",
  };

  if (!data) {
    return temp;
  }

  (Object?.keys(data) as Array<keyof typeof data>)?.forEach((key) => {
    if (key === "amount") return;
    temp[key] = data[key];
  });

  return temp;
};
const getBankInfo = (main: any) => {
  const res: any = {};
  for (const key in main) {
    if (key !== "session_id" && key !== "blockchain_txn_status") {
      res[key] = main[key];
    }
  }
  return res;
};

function FundBankForm() {
  const { appid, txnid } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = state?.background;
  const [inData, setInData] = useState<Istate>(initialstate);
  const resdata = useAppSelector((state) =>
    state.wallet.records.data.find((record) => record.txn_reference === txnid)
  );

  const handleBackDrop = () => {
    if (!appid) return;
    navigate(routes.protected.applications.wallet.link(appid), {
      replace: true,
    });
  };

  const response = getBankInfo(resdata?.meta_data);
  const bank_data = removeAmount(response);

  const dispatch = useAppDispatch();

  const onDropImage = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setInData((prev) => ({
          ...prev,
          image: [{ id: cuid(), name: file.name, data: file }],
        }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onRemoveImage = (id: string) => {
    const temp = inData.image.filter((img: iFileToUpload) => img.id !== id);
    setInData((prev) => ({ ...prev, image: [...temp] }));
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid || !txnid) return;
    const path =
      routes.protected.applications.wallet.fund.awaiting_confirmation.link(
        appid,
        txnid
      );

    const data = {
      appid,
      data: {
        image: inData.image[0].data,
        txn_reference: txnid,
      },
    };

    dispatch(uploadPaymentEvidence(data))
      .unwrap()
      .then(() =>
        navigate(path, {
          state: { background: location },
        })
      );
  };

  return (
    <SideModal onclickBackDrop={handleBackDrop} className="p-8 md:pt-22 md:pl-15 md:pr-28">
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <CustomInput
          label={PageDictionary.form.amount_label}
          value={resdata?.amount}
          readOnly
          disabled
        />

        <div className="flex flex-col gap-y-5">
          <table className="w-full text-left">
            <thead className="text-sm text-gray-410">
              <tr>
                {Object.keys(bank_data).map((key) => {
                  return (
                    <th key={key} className="font-normal capitalize">
                      {removeUnderScoreAddSpace(key)}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="text-base text-base-20">
              <tr>
                {(Object.keys(bank_data) as Array<keyof typeof bank_data>).map(
                  (key) => {
                    return (
                      <td key={key} className="font-normal capitalize">
                        {bank_data[key]}
                      </td>
                    );
                  }
                )}
              </tr>
            </tbody>
          </table>
          <MediaUpload
            title={PageDictionary.form.upload_label}
            images={inData.image}
            onDrop={onDropImage}
            onRemoveMedia={onRemoveImage}
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            name="means_of_identity"
            supportFormatText={PageDictionary.form.upload_supportFormatText}
          />
        </div>

        <CustomButton
          disabled={inData.image.length === 0}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundBankForm;
