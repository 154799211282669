import React from "react";
import { AppLogo } from "../../../static/images/icons";
import { tW } from "../../utils/helpers";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: any;
  isloading?: boolean;
}

function CustomButton({
  variant,
  disabled,
  children,
  className,
  isloading,
  ...props
}: Props) {
  return (
    <>
      <button
        {...props}
        disabled={disabled || isloading}
        className={tW(
          "cursor-pointer rounded-lg py-2 h-14 px-12 font-semibold disabled:cursor-not-allowed disabled:bg-slate-200 ",
          variant
            ? "text-blue_gray-10 border-blue_gray-10 border border-solid bg-white"
            : "bg-cyan-10 text-white",
          isloading ? "flex items-center gap-x-2" : "",
          className
        )}
      >
        {isloading && (
          <span>
            <AppLogo animate hideName scale={0.5} />
          </span>
        )}
        {children}
      </button>
    </>
  );
}

export default CustomButton;
