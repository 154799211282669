import React from "react";
import { NavLink } from "react-router-dom";
import { FixedProps } from "../types";
import { tW } from "../../../../utils/helpers";

export function FixedHorizontalNav({
  navlinks,
  activeclassName,
  className,
  containerClassName,
  listitemclassName,
  handleClick,
}: FixedProps) {
  return (
    <ul
      className={tW(
        "flex flex-grow items-end gap-x-6 py-10 overflow-x-auto",
        containerClassName
      )}
    >
      {navlinks.map(({ link, name }) => (
        <li
          key={name}
          className={tW(
            "select-none first:pr-5 text-[1.125rem] leading-6 first:pl-0 last:pr-0 last:pl-5 whitespace-nowrap",
            listitemclassName
          )}
        >
          <NavLink
            to={link}
            onClick={handleClick}
            className={({ isActive }) =>
              tW(
                "border-b-2 px-2 pb-2",
                isActive
                  ? "border-black font-medium text-black text-[1.375rem]"
                  : "border-transparent font-normal text-gray-360 text-base",
                isActive ? activeclassName : className
              )
            }
          >
            {name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
