import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { FiChevronDown, FiChevronUp, FiCheck } from "react-icons/fi";
import { Label } from "@radix-ui/react-label";
import { iHandleFocus } from "../../types";
import { TbLoaderQuarter } from "react-icons/tb";
import CustomToolTip from "../CustomToolTip";
import Language from "../../utils/language/en";
import { tW } from "../../utils/helpers";

const { input_feild: PageDictionary } = Language.components;

interface Ibasic {
  children: React.ReactNode;
  className?: string;
  idisable?: boolean;
  ihidden?: boolean;
}

interface IselectItem extends Ibasic {
  value: any;
}

interface Iselect extends Ibasic {
  name?: string;
  onChange?: (e: string) => void;
  onFocus?: iHandleFocus;
  disabled?: boolean;
  id?: string;
  className?: string;
  contentClassName?: string;
  iconclassName?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  tooltip?: string;
  hasError?: boolean;
  errortext?: string;
}
export const Select = React.forwardRef(
  (
    {
      children,
      label,
      labelClassName,
      loading,
      tooltip,
      hasError,
      errortext,
      ...props
    }: Iselect,
    forwardedRef: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <div>
        {label ? (
          <Label
            className={tW(
              "mb-2 inline-block text-base font-medium text-base-20",
              tooltip ? "flex items-center gap-x-2" : "",
              labelClassName
            )}
          >
            {label} {tooltip ? <CustomToolTip tip={tooltip} /> : null}
          </Label>
        ) : null}
        <SelectPrimitive.Root onValueChange={props.onChange}>
          <div className="relative">
            <div className="flex items-center gap-x-3">
              <SelectPrimitive.Trigger
                disabled={props.disabled || loading}
                id={props.id}
                className={tW(
                  "relative h-14 w-full rounded-xl border border-solid bg-inputbg p-3 pr-[1.875rem] text-start text-base leading-none text-black",
                  hasError ? "border-red-10" : "border-blue-50",
                  props.className
                )}
                ref={forwardedRef}
                name={props.name}
                value={props.value}
                onFocus={props.onFocus}
                data-testid="radix-select"
              >
                <SelectPrimitive.Value
                  placeholder={
                    props.value
                      ? props.value
                      : props.placeholder
                      ? props.placeholder
                      : ""
                  }
                >
                  {props.value === ""
                    ? props.placeholder
                      ? props.placeholder
                      : ""
                    : props.value}
                </SelectPrimitive.Value>

                <SelectPrimitive.Icon
                  className={tW(
                    "absolute top-1/2 right-3 -translate-y-1/2 ",
                    props.iconclassName
                  )}
                >
                  <FiChevronDown />
                </SelectPrimitive.Icon>
              </SelectPrimitive.Trigger>
              {loading && (
                <span className="animate-spin text-3xl">
                  <TbLoaderQuarter />
                </span>
              )}
            </div>
            {hasError && (
              <p
                data-testid="error-text"
                className="error-text-class absolute -bottom-4 right-0 mt-0.5 text-right text-xs text-red-10"
              >
                {errortext ? errortext : PageDictionary.invalid_value}
              </p>
            )}
          </div>

          <SelectPrimitive.Portal>
            <SelectPrimitive.Content
              className={tW(
                "z-[51] rounded-md border border-blue-50 bg-white",
                props.contentClassName
              )}
              data-cy="cy-select"
              // onCloseAutoFocus={props.onFocus}
            >
              <SelectPrimitive.ScrollUpButton className="select-scroll-btn flex justify-center">
                <FiChevronUp />
              </SelectPrimitive.ScrollUpButton>

              <SelectPrimitive.Viewport className="">
                {children}
              </SelectPrimitive.Viewport>

              <SelectPrimitive.ScrollDownButton className="select-scroll-btn flex justify-center">
                <FiChevronDown />
              </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </div>
    );
  }
);
export const SelectItem = React.forwardRef<HTMLDivElement, IselectItem>(
  ({ children, className, value, ...props }, forwardedRef) => {
    return (
      // eslint-disable-next-line
      <SelectPrimitive.Item
        {...props}
        className={tW(
          "flex cursor-pointer items-center justify-between  px-7 py-4 text-black outline-none first:pt-8 last:pb-8 only:py-4 hover:bg-slate-400 hover:text-white",
          className
        )}
        value={value}
        ref={forwardedRef}
        data-testid={`option-${value}`}
        data-cy={`cy-${value}`}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

        <SelectPrimitive.ItemIndicator>
          <FiCheck />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  }
);
