import { useCallback, useRef } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  usePageTitle,
} from "../../../../common/hooks";
import AppSelector from "../AppSelector";
import { DynamicHorizontalNav as HorizontalNav } from "../../../../common/components/Layout/HorizontalNav";
import { applicationNavLinks } from "../../../../common/utils/helpers/navHelpers";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import { isMobile } from "react-device-detect";

const { page_title: PageDictionary } =
  Language.protected.Layout.application_layout;

type Props = {
  children?: React.ReactNode;
};

function Layout({ children }: Props) {
  const { appid } = useParams();
  const navigate = useNavigate();
  const app = useAppSelector((state) =>
    state.app.allApps.data.find((app) => app._id === appid)
  );
  const layoutRef = useRef<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  usePageTitle(PageDictionary(app?.name));

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, list: any, name: string) => {
      e.preventDefault();
      if (!appid) return;
      const path: string = list.link(appid);
      dispatch(paginationActions.resetData());
      navigate(path);
    },
    [appid, navigate, dispatch]
  );

  return (
    <section className="flex h-full flex-col overflow-scroll py-8 px-8 md:px-16">
      <AppSelector
        layoutRef={layoutRef}
        path={routes.protected.applications.index}
      />
      <nav>
        <HorizontalNav
          navlinks={applicationNavLinks.filter((itm) =>
            isMobile
              ? ["checkout", "include"].includes(itm.name) === false
              : true
          )}
          handleClick={clickHandler}
        />
      </nav>
      {/* <div className="h-full">{children ? children : <Outlet />}</div> */}
      <div className="flex flex-1 flex-col">
        {children ? children : <Outlet context={layoutRef} />}
      </div>
    </section>
  );
}

export default Layout;
