import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./redux";
import { refreshUserToken } from "../../module/auth/store/thunk";

export const useRefreshToken = () => {
  const dispatch = useAppDispatch();
  const { token, user, isRefreshingToken } = useAppSelector(
    (state) => state.auth
  );
  const exp = token.exp;
  const email = user.email;
  const expirationTime = exp * 1000;
  const refreshTime = expirationTime - Date.now() - 120000;


  const refreshAccessToken = () => {
    if (email) {
      dispatch(refreshUserToken(false));
    }
  };

  useEffect(() => {
    if (!email || isRefreshingToken) return;
    // Start a timer to refresh the token 2 minutes before expiration
    const refreshTimer = setTimeout(refreshAccessToken, refreshTime);

    return () => {
      clearTimeout(refreshTimer); // Clear the timer on component unmount
    };
  }, [refreshTime]); //eslint-disable-line

  return;
};
