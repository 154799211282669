import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import ModalTitleHeader from "../../../../common/components/ModalTitleHeader";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButton from "../../../../common/components/CustomButton";
import { iHandleChange } from "../../types";
import {
  addCommas,
  removeCommas,
  removeNonNumeric,
  toCurrency,
} from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import { fundUtilityWallet } from "../../store/thunk";

const { FundWalletForm: PageDictionary } =
  Language.protected.walletModals.funding;

const initialstate = {
  message: "",
  type: "funding_amount",
};

function FundWalletForm() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation().state?.background;
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const {
    utility_balance: { loading },
  } = useAppSelector((state) => state.utility);

  const changeHandler: iHandleChange = useCallback((e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      message: addCommas(removeNonNumeric(value)),
    }));
  }, []);

  const backDrop = () => {
    if (!appid || loading) return;
    // let path = routes.protected.applications.utility.history.link(appid);
    navigate(location.pathname);
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!appid) return;
    dispatch(
      fundUtilityWallet({
        appid,
        data: { amount: removeCommas(inData.message) },
      })
    )
      .unwrap()
      .finally(() => backDrop());
  };

  return (
    <SideModal onclickBackDrop={backDrop} className="p-8 md:pt-22 md:pl-15 md:pr-28">
      <ModalTitleHeader
        title={PageDictionary.header.title}
        subtitle={PageDictionary.header.subtitle}
      />
      <form className="flex flex-col gap-y-10 pt-11" onSubmit={submitHandler}>
        <div className="relative">
          <span className="absolute bottom-5 left-4 z-[2] uppercase">
            {toCurrency(0).split("0.00")}
          </span>
          <CustomInput
            label={PageDictionary.form.amount_label}
            className="pl-[1.75rem]"
            value={inData.message}
            onChange={changeHandler}
          />
        </div>

        <p className="text-sm text-gray-400">
          {PageDictionary.form.warn_text_utility}
        </p>

        <CustomButton
          disabled={inData.message === ""}
          isloading={loading}
          className="mt-3 self-start rounded-lg py-3 px-10 text-sm leading-6"
        >
          {PageDictionary.form.submit_btn}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default FundWalletForm;
