import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import * as tp from "../types";

export const createBusinessDocument = createAsyncThunk(
  "compliance/business-document/create",
  async (
    data: tp.IcreateBusinessDocument,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service.business_document
      .create(data)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listBusinessDocuments = createAsyncThunk(
  "compliance/business-document/list",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .list()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateBusinessDocument = createAsyncThunk(
  "compliance/business-document/update",
  async (
    req: tp.IupdateBusinessDocument,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service.business_document
      .update(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const retriveBusinessDocument = createAsyncThunk(
  "compliance/business-document/retrive",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .retrive(id)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const addShareholder = createAsyncThunk(
  "compliance/shareholder/add",
  async (req: tp.IaddShareholder, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .add(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const listShareholders = createAsyncThunk(
  "compliance/shareholder/list",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .list()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const removeShareholder = createAsyncThunk(
  "compliance/shareholder/remove",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .remove(id)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
export const retriveShareholder = createAsyncThunk(
  "compliance/shareholder/retrive",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .retrive(id)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const createBusinessProfile = createAsyncThunk(
  "compliance/business-profile/create",
  async (
    req: tp.IcreateBusinessProfile,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service.business_profile
      .create(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const retriveBusinessProfile = createAsyncThunk(
  "compliance/business-profile/retrive",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.business_profile
      .retrive()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        // customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);

export const updateBusinessProfile = createAsyncThunk(
  "compliance/business-profile/update",
  async (
    req: tp.IupdateBusinessProfileThunk,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service.business_profile
      .update(req)
      .then((res) => {
        customToast.success(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast.error(err.message);
        return rejectWithValue(err);
      });
  }
);
