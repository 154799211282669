import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "../../../../common/components/CustomDropDown";
import { listRiskRecords } from "../../store/thunk";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";
import { tW } from "../../../../common/utils/helpers";

const filterlist = [
  {
    name: "Total TX freqiesncy",
    value: "max_daily_txn_freq",
  },
  {
    name: "Threshold amount  ( Per day)",
    value: "max_daily_txn_amount",
  },
  {
    name: "Threshold amount  ( Per time)",
    value: "max_transaction_amount",
  },
  {
    name: "Time In between two tx",
    value: "allowed_duration_btw_transaction",
  },
  {
    name: "Quarantime Tx amount",
    value: "quarantine_transaction_amount",
  },
  {
    name: "Quarantine Tx frequency",
    value: "quarantine_transaction_freq",
  },
];

function RiskRecordQuery() {
  const { appid, policyid } = useParams();
  const dispatch = useAppDispatch();
  // const query = useAppSelector((state) => state.app.policy);
  const { filter } = useAppSelector((state) => state.pagination);
  const selected = filterlist.find((itm) => itm.value === filter.search);

  const updateQueryHandler = (value: string) => {
    if (!appid || !policyid) return;
    dispatch(paginationActions.addQueryParams({ key: "", value }));

    dispatch(listRiskRecords({ appid, pid: policyid, query: value }));

    // if (value !== "") {
    //   dispatch(
    //     appActions.resetPolicyNotificationAndRiskPageData("risk_policies")
    //   );
    //   dispatch(listRiskRecords({ appid, pid: policyid, query: value }));
    // }
    // dispatch(appActions.updateQueryParams(value));
  };

  // useEffectOnce(() => {
  //   return () => {
  //     updateQueryHandler("");
  //   };
  // });

  return (
    <form
      className="flex items-center gap-x-5"
      onSubmit={(e) => e.preventDefault()}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="h-12 min-w-[14rem] cursor-pointer appearance-none rounded-xl border border-gray-340 bg-arrowdown  bg-[90%]  bg-no-repeat py-3 pl-6 pr-11 text-start text-base font-bold text-gray-60">
            {!selected?.name ? "Filter by:" : selected?.name}
          </button>
        </DropdownMenuTrigger>
        <DropDownMenuContent className="text-blue_gray-10 z-10 flex w-72 cursor-pointer flex-col rounded-lg bg-white font-bold shadow-pool_card">
          {filterlist.map((list) => (
            <DropDownItem key={list.value} className="group w-full">
              <button
                onClick={() => updateQueryHandler(list.value)}
                className={tW(
                  "flex w-full items-center gap-x-2 px-4 py-2 text-sm group-first:pt-3.5 group-last:pb-7 group-only:py-4",
                  selected?.value === list.value ? "text-red-10" : "text-black"
                )}
              >
                <span>{list.name}</span>
              </button>
            </DropDownItem>
          ))}
        </DropDownMenuContent>
      </DropdownMenu>
      <button
        onClick={() => updateQueryHandler("")}
        className={tW(!selected?.name ? "cursor-[inherit] opacity-0" : "")}
      >
        reset
      </button>
    </form>
  );
}

export default RiskRecordQuery;
