import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../common/hooks";
import SideModal from "../../../../../../common/components/CustomModal/SideModal";
import customToast from "../../../../../../common/components/CustomToast";
import { walletActions } from "../../../../store/slice";
import { listAppTransactions } from "../../../../store/thunk";
import loading from "../../../../../../static/images/loading.gif";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";
import { tW } from "../../../../../../common/utils/helpers";

const { PaymentProcessing: PageDictionary } =
  Language.protected.walletModals.funding;

function PaymentPrcocessing() {
  const { appid } = useParams();
  const [stage, setStage] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { response } = useAppSelector((state) => state.wallet.socket);
  const dispatch = useAppDispatch();

  const transactionInitiatedSuccess = (txn_ref: string) => {
    if (!appid) return;
    const background = state?.background;
    const path = routes.protected.applications.wallet.fund.bank.link(
      appid,
      txn_ref
    );
    navigate(path, { state: { background } });
  };
  const transactionInitiatedError = () => {
    if (!appid) return;
    if (response?.message) {
      customToast.error(response?.message);
    }
    dispatch(walletActions.startDisconecting());
    const path = routes.protected.applications.wallet.link(appid);
    navigate(path);
  };

  const backdropClickHandler = () => {
    if (!appid) return;
    if (
      response?.message_type === "transaction_initiated" ||
      response?.message_type === "funding_not_accepted"
    ) {
      const path = routes.protected.applications.wallet.link(appid);
      dispatch(walletActions.startDisconecting());
      navigate(path);
    }
  };

  useEffect(() => {
    if (!appid) return;
    switch (response?.message_type) {
      case "transaction_initiated":
        if (response.status === "error") {
          transactionInitiatedError();
        } else {
          setStage(true);
        }
        break;
      case "funding_not_accepted":
        dispatch(listAppTransactions({ appid }))
          .unwrap()
          .then(transactionInitiatedError);
        break;
      case "funding_accepted":
        setStage(true);
        dispatch(
          listAppTransactions({
            appid,
          })
        )
          .unwrap()
          .then(() =>
            transactionInitiatedSuccess(response.data?.txn_reference)
          );
        break;

      default:
        break;
    }
  }, [JSON.stringify(response)]); //eslint-disable-line

  return (
    <SideModal
      onclickBackDrop={backdropClickHandler}
      className="flex flex-col gap-y-20 p-8 md:pt-22 md:pl-15 md:pr-28"
    >
      <div className="flex items-center justify-center">
        <img
          className="h-60 w-60 object-contain text-center"
          src={loading}
          alt="loading"
        />
      </div>

      <div className="grid grid-cols-[max-content_max-content_1fr_max-content_max-content] grid-rows-2 items-center px-34">
        <div className="col-start-2 col-end-3 row-start-1 row-end-2 ml-7 flex h-9 w-9 items-center justify-center rounded-full bg-green-50 text-xs text-white">
          1
        </div>
        <span className="relative col-start-3 col-end-4 row-start-1 row-end-2 inline-flex h-1 flex-1 bg-gray-460">
          <span
            className={tW(
              "absolute top-0 left-0 inline-block h-full bg-green-50",
              stage ? "w-full animate-none" : "w-1/2 animate-slide_left"
            )}
          />
        </span>
        <div
          className={tW(
            "col-start-4 col-end-5 row-start-1 row-end-2 mr-7 flex h-9 w-9 items-center justify-center rounded-full  text-xs text-white",
            stage ? "bg-green-50" : "bg-gray-460"
          )}
        >
          2
        </div>
        <p className="col-start-1 col-end-4 row-start-2 row-end-3 w-28 text-center text-sm text-green-50">
          {PageDictionary.awaiting}
        </p>
        <p
          className={tW(
            "col-start-3 col-end-6 row-start-2 row-end-3 w-32 justify-self-end text-center text-sm ",
            stage ? "text-green-50" : ""
          )}
        >
          {PageDictionary.fetching}
        </p>
      </div>

      <div>
        <h2 className="text-center text-3236 font-bold">
          {PageDictionary.processing}
        </h2>
        <p className="mx-auto mt-6 max-w-[16rem] text-center text-sm font-normal text-gray-410">
          {PageDictionary.routing}
        </p>
      </div>
    </SideModal>
  );
}

export default PaymentPrcocessing;
