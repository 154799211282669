import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppLogo } from "../../../../static/images/icons";
import { navComponents } from "../../../utils/helpers/navHelpers";
import MultiUserId from "../../MultiUserId";
import { IoLogOut } from "react-icons/io5";
import routes from "../../../routes";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { logUserOut } from "../../../../module/auth/store/thunk";
import Language from "../../../utils/language/en";
import { paginationActions } from "../../../../store/helperReducers/paginationSlice";
import { tW } from "../../../utils/helpers";

type props = {
  className?: string;
  isPublic?: boolean;
};

const { sidenav: PageDictionary } = Language.protected.Layout;
// routes
const {
  settings,
  compliance,
  onboarding,
  dashboard,
  applications,
  user: admins,
} = routes.protected;

function SideNav({ className, isPublic }: props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { loading, user, company } = useAppSelector((state) => state.auth);
  const agreement_complete = company?.agreement_info?.signature?.complete;
  const { user_types, email } = user;
  const home_link =
    company?.admin_approved === false ? onboarding.terms.index : dashboard;

  const logoutHandler = () => {
    navigate(settings.logout, {
      replace: true,
      state: { background: location },
    });
    dispatch(logUserOut())
      .unwrap()
      .finally(() => navigate(routes.nonprotected.login, { replace: true }));
    // .catch((err) => navigate(location.pathname, { replace: true }));
  };

  const navClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const { href } = e.currentTarget;

    if (
      href.includes(admins.index) ||
      href.includes(compliance.index) ||
      href.includes(applications.index)
    ) {
      dispatch(paginationActions.resetData());
    }
    if (href.includes(settings.index)) {
      e.preventDefault();
      navigate(settings.security);
    }
    if (href.includes(compliance.index)) {
      e.preventDefault();
      navigate(compliance.business_document.index);
    }
    if (href.includes(onboarding.index)) {
      e.preventDefault();
      let path = onboarding.terms.index;
      if (agreement_complete) {
        path = onboarding.shareholders.index;
      }
      navigate(path);
    }
  };

  return (
    <nav
      className={tW(
        "grid w-16 grid-rows-[max-content_8.5rem_max-content_2.6fr_max-content_1fr_max-content] pt-11 pb-22 shadow-side_nav md:w-72",
        className
      )}
    >
      <Link
        to={home_link}
        className="row-start-1 row-end-2 flex h-9 items-center justify-center"
      >
        <span className="text-black md:hidden">
          <AppLogo hoverAnimate hideName />
        </span>
        <span className="hidden text-black md:block">
          <AppLogo hoverAnimate />
        </span>
      </Link>
      <div
        data-tut="instextour__nav-navigation"
        className="row-start-3 row-end-4 flex flex-col gap-y-5 px-3 md:pl-10 md:pr-8"
      >
        {navComponents.map(({ icon, link, title, name }) => {
          if (isPublic && name !== "onboarding") return null;
          if (name !== "onboarding" && company?.admin_approved === false) {
            return null;
          }
          if (name === "onboarding" && company?.admin_approved === true) {
            return null;
          }
          if (name === "user" && user_types.includes("WORKSPACE_MEMBER")) {
            return null;
          }
          return (
            <NavLink
              key={link}
              onClick={navClickHandler}
              to={link}
              data-tut={`instextour__nav-${name}`}
              className={({ isActive }) =>
                tW(
                  "flex items-center justify-center gap-x-6 p-2 text-base leading-5 md:justify-start md:py-3 md:px-6",
                  isActive
                    ? "relative rounded-2xl bg-black text-white"
                    : "text-gray-10"
                )
              }
            >
              {icon}
              <span className="hidden md:inline-block">{title}</span>
            </NavLink>
          );
        })}
      </div>
      {email && (
        <button
          disabled={loading}
          onClick={logoutHandler}
          className="group row-start-5 row-end-6  text-base text-red-10 hover:text-gray-10 md:pl-10 md:pr-8"
        >
          <span className="flex items-center justify-center gap-x-6 py-3 md:px-6 ">
            <IoLogOut className="h-7 w-7 transition-all duration-300 ease-in-out group-hover:scale-x-[-1] group-hover:transition-all group-hover:duration-300 group-hover:ease-in-out" />
            <span className="hidden md:inline-block">
              {PageDictionary.logout}
            </span>
          </span>
        </button>
      )}
      <MultiUserId className="row-start-6 row-end-7 hidden select-none self-end text-center md:block" />
    </nav>
  );
}

export default SideNav;
