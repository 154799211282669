import { useCallback, useRef } from "react";
import ApplicationLayout from "../";
import {
  useLocation,
  useNavigate,
  useParams,
  Outlet,
  NavLink,
} from "react-router-dom";
import CustomToggle from "../../../../../common/components/CustomToggle";
import { useAppSelector } from "../../../../../common/hooks";
import { Iapp } from "../../../types";
import routes from "../../../../../common/routes";
import Language from "../../../../../common/utils/language/en";
import { includeNavLinks } from "../../../../../common/utils/helpers/navHelpers";

const { Include_page: PageDictionary } = Language.protected.ApplicationPages;

interface Props {
  children?: React.ReactNode;
}

function Layout({ children }: Props) {
  const layoutRef = useRef<HTMLElement | null>(null);
  const appid = useParams()?.appid as string;
  const { data: allApps, loading } = useAppSelector(
    (state) => state.app.allApps
  );
  const app = allApps.find((app) => app._id === appid) as Iapp;
  const navigate = useNavigate();
  const location = useLocation();

  const includeToggleHandler = () => {
    let path = "";
    if (loading) return;
    if (!app.include_is_active) {
      // user can manually input keys or upload the generated file
      path =
        routes.protected.applications.include.toggle_include.on.link(appid);
    } else {
      // regenerate api keys when you turn off
      path =
        routes.protected.applications.include.toggle_include.off.link(appid);
    }
    navigate(path, { state: { background: location } });
  };

  const clickHandler = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, list: any) => {
      e.preventDefault();
      if (!appid) return;
      const path: string = list.link(appid);
      navigate(path);
    },
    [appid, navigate]
  );

  return (
    <ApplicationLayout>
      <div className="flex flex-col gap-y-6 pt-5">
        <div className="">
          <div className="mb-[1.625rem] flex items-center gap-x-20 ">
            <h1 className="text-2226 font-semibold text-[#52525B]">
              {PageDictionary.pageTitle}
            </h1>
            <CustomToggle
              label={PageDictionary.hero.active_label}
              check={app.include_is_active}
              className="w-max gap-x-3"
              onToggle={includeToggleHandler}
              isLoading={loading}
            />
          </div>
          <div className="max-w-3xl">
            {!app.include_is_active ? (
              <p className="text-sm font-medium text-red-90">
                {PageDictionary.hero.inactive_message}
              </p>
            ) : null}
          </div>
        </div>
        {app.include_is_active && (
          <div className="min-h-[26.313rem] bg-[#F6F6F6] py-[1.438rem] px-[2.25rem]">
            <div className="mb-[2rem]">
              <nav className="flex items-center gap-x-[3.125rem]">
                {includeNavLinks.map(({ link, name }) => (
                  <NavLink
                    key={name}
                    to={link.index.replace(":appid", appid)}
                    onClick={(e) => clickHandler(e, link)}
                    className={({ isActive }) =>
                      `min-w-[10rem] rounded-lg ${
                        isActive
                          ? "bg-[#000000] text-[#F9FAFB]"
                          : "text-[#52525B]"
                      } px-6 py-3 text-center text-lg font-bold `
                    }
                  >
                    {name}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className="">{children || <Outlet context={layoutRef} />}</div>
          </div>
        )}
      </div>
    </ApplicationLayout>
  );
}

export default Layout;
