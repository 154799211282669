import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomInput from "../../../../../common/components/CustomInput";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import routes from "../../../../../common/routes";
import { ArrowBack } from "../../../../../static/images/icons";
import { addCryptoAddress } from "../../../store/thunk";
import { iHandleChange, iHandleSubmit } from "../../../types";
import Language from "../../../../../common/utils/language/en";

const { CryptoSetting: PageDictionary } =
  Language.protected.walletModals.withdrawalSetting;

function CryptoSetting() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading } = useAppSelector((state) => state.wallet.withdrawal);
  const dispatch = useAppDispatch();
  const [data, setData] = useState({ address: "" });

  const background_pathname = location.state?.background?.pathname;
  const disable_sbt_btn = data.address === "";

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const backHandler = () => {
    if (loading) return;
    navigate(background_pathname, { replace: true });
  };

  const backDropHandler = () => {
    backHandler();
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!appid) return;
    const link =
      routes.protected.applications.wallet.settings.withdrawal_details.success.link(
        appid
      );
    const req = {
      appid,
      data,
    };

    dispatch(addCryptoAddress(req))
      .unwrap()
      .then(() =>
        navigate(link, {
          state: { background: location.state?.background },
          replace: true,
        })
      );
  };

  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className="p-8 md:pt-15 md:pl-14 md:pr-28 md:pb-18"
    >
      <header className="flex items-center gap-x-8 text-3643 ">
        <button onClick={backHandler}>
          <span className="flex h-8 w-8">
            <ArrowBack />
          </span>
        </button>
        <h2>{PageDictionary.title}</h2>
      </header>
      <form className="flex flex-col gap-y-15 pt-12" onSubmit={submitHandler}>
        <h3 className="text-2017 font-medium text-gray-430">
          {PageDictionary.form.details}
        </h3>
        <div className="flex flex-col gap-y-9">
          <CustomInput
            label={PageDictionary.form.asset}
            labelClassName="capitalize"
            value="USDT"
            readOnly
            disabled
          />
          <CustomInput
            label={PageDictionary.form.address}
            name="address"
            labelClassName="capitalize"
            value={data.address}
            onChange={changeHandler}
          />

          <div>
            <div>
              <h4 className="font-medium text-base-20 ">
                {PageDictionary.form.network.title}
              </h4>
              <p className="mt-4 text-2017 font-bold text-gray-430">
                {PageDictionary.form.network.name}
              </p>
            </div>
            <p className="mt-12 text-red-10">
              {PageDictionary.form.network.note.title}
              <br />
              {PageDictionary.form.network.note.sub}
            </p>
          </div>

          <CustomButton
            disabled={disable_sbt_btn}
            isloading={loading}
            className="self-start py-3.5 px-14"
          >
            {PageDictionary.form.submit}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}

export default CryptoSetting;
