import { ReactNode } from "react";
import { toast, ToastPosition } from "react-toastify";

type ToastConfig = {
  position: ToastPosition;
  draggable: boolean;
  bodyClassName: string;
  className: string;
  autoClose: number;
  progressClassName: string;
};

const config: ToastConfig = {
  position: "top-right",
  draggable: false,
  bodyClassName: "toast__body",
  className: "toast",
  autoClose: 2000,
  progressClassName: "toast__progress",
};
const customToast = {
  error: (res: any[] | string) => {
    const data = typeof res === "object" ? res[0] : res;
    return toast(data, {
      ...config,
      type: "error",
    });
  },
  success: (res: any[] | string) => {
    const data = typeof res === "object" ? res[0] : res;
    return toast(data, {
      ...config,
      type: "success",
    });
  },
  warning: (res: ReactNode | string, moreConfig: any) => {
    const data = typeof res === "string" ? res : res;
    return toast(data, {
      ...config,
      ...moreConfig,
      type: "warning",
      position: "bottom-center",
      autoClose: false,
      closeOnClick: false,
      toastId: "TEST-MODE",
    });
  },
};

export { toast };

export default customToast;
