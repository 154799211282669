import { lazy } from "react";
import routes from ".";
import { Iroute } from "../types";

const loadModules = (module: string, link: string) =>
  lazy(() => import(`../../module/${module}/pages/${link}`));

const route: Iroute[] = [
  {
    path: routes.nonprotected.login,
    Component: loadModules("auth", "Login"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.register,
    Component: loadModules("auth", "Register"),
    access: "guest-only",
  },
  // {
  //   path: routes.nonprotected.two_fa,
  //   Component: loadModules("auth", "TwoFA"),
  //   access: "guest-only",
  // },
  {
    path: routes.nonprotected.resetpassword,
    Component: loadModules("auth", "RestPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.forgotpassword,
    Component: loadModules("auth", "ForgotPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.verified,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.enter_otp,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.verify_with_2fa,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.new_password,
    Component: loadModules("auth", "NewPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.reset_successful,
    Component: loadModules("auth", "ResetSuccessful"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.welcome,
    Component: loadModules("auth", "Welcome"),
    access: "guest-only",
  },
  {
    path: routes.protected.dashboard,
    Component: loadModules("dashboard", "Dashboard"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.applications.index,
    Component: loadModules("applications", "Application"),
    access: "loggedin-user",
  },
  // {
  //   path: routes.protected.applications.transaction_information.index,
  //   Component: loadModules("applications", "TransactionInformation"),
  //   access: "loggedin-user",
  // },
  {
    path: routes.protected.applications.incomingTx.index,
    Component: loadModules("applications", "IncomingSettlement"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.outgoingTx.index,
    Component: loadModules("applications", "OutgoingTx"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.policy.index,
    Component: loadModules("applications", "Policy"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.include.url.index,
    Component: loadModules("applications", "Include/Url"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.include.config.index,
    Component: loadModules("applications", "Include/Config"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.include.pricing.index,
    Component: loadModules("applications", "Include/Pricing"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.include.wallet.index,
    Component: loadModules("applications", "Include/Wallet"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.policy.risk_record.index,
    Component: loadModules("applications", "RiskRecord"),
    access: "loggedin-user",
    layout: "policy",
  },
  {
    path: routes.protected.applications.policy.notification_config.index,
    Component: loadModules("applications", "NotificationConfig"),
    access: "loggedin-user",
    layout: "policy",
  },
  {
    path: routes.protected.applications.users.index,
    Component: loadModules("applications", "Users"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.api_settings.index,
    Component: loadModules("applications", "ApiSettings"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.checkout_settings.index,
    Component: loadModules("applications", "CheckoutSettings"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.utility.history.index,
    Component: loadModules("utility", "UtilityHistory"),
    access: "loggedin-user",
    layout: "utility",
  },
  {
    path: routes.protected.applications.utility.records.index,
    Component: loadModules("utility", "WalletRecords"),
    access: "loggedin-user",
    layout: "utility",
  },
  {
    path: routes.protected.applications.wallet.index,
    Component: loadModules("wallet", "Wallet"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.analytics.index,
    Component: loadModules("applications", "Analytics"),
    access: "loggedin-user",
    layout: "application",
  },
  {
    path: routes.protected.applications.include.index,
    Component: loadModules("applications", "Include/Wallet"),
    access: "loggedin-user",
    layout: "include",
  },
  {
    path: routes.protected.applications.wallet.settings.withdrawal_details.bank
      .index,
    Component: loadModules("wallet", "WithdrawalSettingBank"),
    access: "loggedin-user",
    layout: "withdrawal-setting",
  },
  {
    path: routes.protected.applications.wallet.settings.withdrawal_details
      .crypto.index,
    Component: loadModules("wallet", "WithdrawalSettingCrypto"),
    access: "loggedin-user",
    layout: "withdrawal-setting",
  },
  {
    path: routes.protected.compliance.business_document.index,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.compliance.shareholders.index,
    Component: loadModules("compliance", "Shareholders"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.compliance.bussiness_profile,
    Component: loadModules("compliance", "BusinessProfile"),
    access: "loggedin-user",
    layout: "compliance",
  },
  {
    path: routes.protected.settings.security,
    Component: loadModules("settings", "Security"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.business_profile,
    Component: loadModules("settings", "BusinessProfile"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.transaction_country,
    Component: loadModules("settings", "TransactionCountry"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.activity_log,
    Component: loadModules("settings", "ActivityLog"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.user.index,
    Component: loadModules("user", "User"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.onboarding.terms.index,
    Component: loadModules("onboarding", "Terms"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.preview_app,
    Component: loadModules("onboarding", "PreviewApp"),
    access: "public",
  },
  {
    path: routes.protected.onboarding.business_document.index,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "loggedin-user",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.bussiness_profile,
    Component: loadModules("compliance", "BusinessProfile"),
    access: "loggedin-user",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.shareholders.index,
    Component: loadModules("compliance", "Shareholders"),
    access: "loggedin-user",
    layout: "onboarding",
  },
];

export default route;
