import { StatBoardQuery } from "../../../module/dashboard/types";

export const ENDPOINTS = {
  auth: {
    signin: {
      method: "POST",
      url: "/auth/login/",
    },

    signout: {
      method: "POST",
      url: "/auth/logout",
    },

    email_validate: {
      method: "POST",
      url: "/auth/email/valid",
    },

    signup: {
      method: "POST",
      url: "/auth",
    },

    verified: {
      method: "POST",
      url: "/auth/account/verify",
    },

    initiate_reset_password: {
      method: "POST",
      url: "/auth/recovery/initiate",
    },
    validate_reset_password: {
      method: "POST",
      url: "/auth/recovery/token-verify",
    },

    resend_token: {
      method: "post",
      url: "/auth/token/send",
    },

    refresh_token: {
      method: "POST",
      url: "/auth/token/refresh",
    },
    send_2fa_verification_token: {
      method: "POST",
      url: "/auth/2fa/otp-send",
    },
    verify_2fa_code: {
      method: "POST",
      url: "/auth/2fa/verify",
    },
    get_country: {
      method: "GET",
      url: `/auth/assets`,
    },
  },
  dashboard: {
    get_summary: {
      method: "GET",
      url: "/dashboard/summary",
    },
    graph_data: {
      method: "GET",
      url: ({ freq, cat }: StatBoardQuery) =>
        `/dashboard/graph?frequency=${freq}&transaction_category=${cat}`,
    },
  },
  application: {
    create_app: {
      method: "POST",
      url: "/app",
    },
    list_apps: {
      method: "GET",
      url: "/app",
    },
    update_apps: {
      method: "PATCH",
      url: (appid: string) => `/app/${appid}`,
    },
    download_api_keys: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/api-key/download`,
    },
    regenerate_api_keys: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/api-key/generate`,
    },
    retrieve_api_keys: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/api-key/retrieve`,
    },
    go_live: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/mode`,
    },
    include_switch_on: {
      method: "POST",
      url: (appid: string) => `/include/app/${appid}/on`,
    },
    include_switch_off: {
      method: "POST",
      url: (appid: string) => `/include/app/${appid}/off`,
    },
    add_custom_domain: {
      method: "POST",
      url: (appid: string) => `/include/app/${appid}/domain`,
    },
    verify_domain: {
      method: "GET",
      url: (appid: string) => `/include/app/${appid}/domain/verify`,
    },
    check_domain_availability: {
      method: "POST",
      url: (appid: string) => `/include/app/${appid}/domain/check`,
    },
    remove_custom_domain: {
      method: "POST",
      url: (appid: string) => `/include/app/${appid}/domain/remove`,
    },
  },
  withdrawal: {
    list_bank_account_types: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/withdrawal/bank-account-types`,
    },
    list_deactivated_accounts: {
      method: "GET",
      url: (appid: string) =>
        `/app/${appid}/withdrawal/bank-account/deactivated`,
    },
    add_bank_account: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/withdrawal/bank-account`,
    },
    get_bank_account: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/withdrawal/bank-account`,
    },
    update_bank_account: {
      method: "PATCH",
      url: (appid: string, bankid: string) =>
        `/app/${appid}/withdrawal/bank-account/${bankid}`,
    },
    bank_withdrawal: {
      method: "POST",
      url: (appid: string) =>
        `/app/${appid}/withdrawal/initiate?withdrawal_mode=FIAT`,
    },
    crypto: {
      add_wallet: {
        method: "POST",
        url: (appid: string) => `/app/${appid}/withdrawal/crypto-wallet`,
      },
      get_wallet: {
        method: "GET",
        url: (appid: string) => `/app/${appid}/withdrawal/crypto-wallet`,
      },
      deactivate_wallet: {
        method: "PATCH",
        url: (appid: string, walletid: string) =>
          `/app/${appid}/withdrawal/crypto-wallet/${walletid}`,
      },
    },
  },
  utility: {
    get_wallet: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/utility/wallet/retrieve`,
    },
    fund_wallet: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/utility/wallet/fund`,
    },
    withdraw_funds: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/utility/wallet/withdraw`,
    },
    get_withdrawal_threshold: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/utility/wallet/alert-threshold`,
    },
    set_withdrawal_threshold: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/utility/wallet/alert-threshold`,
    },
    remove_withdrawal_threshold: {
      method: "POST",
      url: (appid: string) =>
        `/app/${appid}/utility/wallet/alert-threshold/remove`,
    },
    list_utility_category: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/utility/wallet/categories`,
    },
    list_single_utility_category: {
      method: "GET",
      url: (appid: string, utilid: string) =>
        `/app/${appid}/utility/wallet/categories/${utilid}`,
    },
    utility_history: {
      method: "GET",
      url: (
        appid: string,
        page: number,
        size: number,
        country?: string,
        cat?: string,
        sub_cat?: string
      ) =>
        `/app/${appid}/transactions/external?category=utility&page=${page}&page_size=${size}${
          country ? `&country=${country}` : ""
        }`,
    },
    utility_wallet_record: {
      method: "GET",
      url: (appid: string, page: number, size: number) =>
        `/app/${appid}/transactions?category=utility&page=${page}&page_size=${size}`,
    },
  },
  wallet: {
    get_wallet: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/wallet/retrieve`,
    },
    upload_payment_evidence: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/wallet/fund/evidence`,
    },
    list_transactions: {
      method: "GET",
      url: (
        appid: string,
        page: number,
        size: number,
        type?: string,
        search?: string
      ) =>
        `/app/${appid}/transactions/external?page=${page}&page_size=${size}${
          type ? `&type=${type}` : ""
        }${search ? `&name=${search}` : ""}`,
    },
    get_transaction: {
      method: "GET",
      url: (appid: string, txnid: string) =>
        `/app/${appid}/transactions/external/${txnid}`,
    },
    report_transaction: {
      create: {
        method: "POST",
        url: (appid: string, txnid: string) =>
          `/app/${appid}/transactions/${txnid}/report`,
      },
      list_report: {
        method: "GET",
        url: (appid: string, txnid: string) =>
          `/app/${appid}/transactions/${txnid}/report`,
      },
      list_single_report: {
        method: "GET",
        url: (appid: string, txnid: string, rid: string) =>
          `/app/${appid}/transactions/${txnid}/report/${rid}`,
      },
      update_report_status: {
        method: "PATCH",
        url: (appid: string, txnid: string, rid: string) =>
          `/app/${appid}/transactions/${txnid}/report/${rid}`,
      },
    },
    list_app_transactions: {
      method: "GET",
      url: (appid: string, type?: string) =>
        `/app/${appid}/transactions?page_size=5${type ? `&type=${type}` : ""}`,
    },
    get_app_transaction: {
      method: "GET",
      url: (appid: string, txnid: string, type?: string) =>
        `/app/${appid}/${txnid}/transactions?${type ? `&type=${type}` : ""}`,
    },
    get_exchange_rate: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/wallet/exchange-rate`,
    },
    withdrawal_threshold: {
      add_threshold: {
        method: "POST",
        url: (appid: string) => `/app/${appid}/withdrawal/alert-threshold`,
      },
      list_thresholds: {
        method: "GET",
        url: (appid: string) => `/app/${appid}/withdrawal/alert-threshold`,
      },
      update_threshold: {
        method: "PATCH",
        url: (appid: string, thresholdid: string) =>
          `/app/${appid}/withdrawal/alert-threshold/${thresholdid}`,
      },
      delete_threshold: {
        method: "DELETE",
        url: (appid: string, thresholdid: string) =>
          `/app/${appid}/withdrawal/alert-threshold/${thresholdid}`,
      },
    },
  },
  app_user: {
    list_app_users: {
      method: "GET",
      url: (appid: string, page: number, search?: string) =>
        `/app/${appid}/consumer?page=${page}&page_size=10${
          search ? `&first_name=${search}` : ""
        }`,
    },
    retrive_app_user: {
      method: "GET",
      url: (appid: string, customerid: string) =>
        `/app/${appid}/consumer/${customerid}`,
    },
    update_app_user_status: {
      method: "PATCH",
      url: (appid: string, customerid: string) =>
        `/app/${appid}/consumer/${customerid}/status`,
    },
  },
  policy: {
    create_policy: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/policy`,
    },
    list_policies: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/policy`,
    },
    update_policy: {
      method: "PATCH",
      url: (appid: string, policyid: string) =>
        `/app/${appid}/policy/${policyid}`,
    },
    retrive_policy: {
      method: "GET",
      url: (appid: string, policyid: string) =>
        `/app/${appid}/policy/${policyid}`,
    },
    create_category: {
      method: "POST",
      url: (appid: string) => `/app/${appid}/user-category`,
    },
    list_categories: {
      method: "GET",
      url: (appid: string) => `/app/${appid}/user-category?is_active=true`,
    },
    update_category: {
      method: "PATCH",
      url: (appid: string, catid: string) =>
        `/app/${appid}/user-category/${catid}`,
    },
    retrive_category: {
      method: "GET",
      url: (appid: string, catid: string) =>
        `/app/${appid}/user-category/${catid}`,
    },
    list_risk_records: {
      method: "GET",
      url: (appid: string, polid: string, query?: string) =>
        `/app/${appid}/policy/${polid}/risk-records?${
          query ? `rule_type=${query}` : ""
        }`,
    },
    notification_config: {
      add: {
        method: "POST",
        url: (appid: string, polid: string) =>
          `/app/${appid}/policy/${polid}/notification-config`,
      },
      list: {
        method: "GET",
        url: (appid: string, polid: string) =>
          `/app/${appid}/policy/${polid}/notification-config`,
      },
      update: {
        method: "PATCH",
        url: (appid: string, polid: string, notid: string) =>
          `/app/${appid}/policy/${polid}/notification-config/${notid}`,
      },
      retrieve: {
        method: "GET",
        url: (appid: string, polid: string, notid: string) =>
          `/app/${appid}/policy/${polid}/notification-config/${notid}`,
      },
    },
  },
  compliance: {
    business_document: {
      create: {
        method: "POST",
        url: "/compliance/business/document",
      },
      list: {
        method: "GET",
        url: "/compliance/business/document",
      },
      update: {
        method: "PATCH",
        url: (id: string) => `/compliance/business/document/${id}`,
      },
      retrive: {
        method: "GET",
        url: (id: string) => `/compliance/business/document/${id}`,
      },
    },
    shareholder: {
      add: {
        method: "POST",
        url: "/compliance/shareholder",
      },
      list: {
        method: "GET",
        url: "/compliance/shareholder",
      },
      remove: {
        method: "PATCH",
        url: (id: string) => `/compliance/shareholder/${id}`,
      },
      retrive: {
        method: "GET",
        url: (id: string) => `/compliance/shareholder/${id}`,
      },
    },
    business_profile: {
      create: {
        method: "POST",
        url: "/compliance/business/profile",
      },
      retrive: {
        method: "GET",
        url: "/compliance/business/profile",
      },
      update: {
        method: "PATCH",
        url: (id: string) => `/compliance/business/profile/${id}`,
      },
    },
    agreement_info: {
      save_agreement_info: {
        method: "POST",
        url: "/compliance/agreement/info",
      },
      generate_signature_ref: {
        method: "POST",
        url: "/compliance/agreement/generate-signature-reference",
      },
      complete_agreement: {
        method: "POST",
        url: "/compliance/agreement/complete",
      },
      generate_signature_agreement_link: {
        method: "POST",
        url: "/compliance/agreement/generate-agreement-link",
      },
      no_auth: {
        retrieve_agreement: {
          method: "GET",
          url: (cid: string, token: string) =>
            `/compliance/no-auth-agreement/${cid}/${token}/agreement-link`,
        },
        generate_signature_reference: {
          method: "POST",
          url: (cid: string, token: string) =>
            `/compliance/no-auth-agreement/${cid}/${token}/generate-signature-reference`,
        },
        complete_agreement: {
          method: "POST",
          url: (cid: string, token: string) =>
            `/compliance/no-auth-agreement/${cid}/${token}/complete`,
        },
      },
    },
  },
  setting: {
    update_password: {
      method: "POST",
      url: "/user/password-update",
    },
    initiate_password_update: {
      method: "POST",
      url: "/user/initiate-password-update",
    },
    resend_password_update: {
      method: "POST",
      url: "/user/resend-password-update-otp",
    },
    get_user_info: {
      method: "GET",
      url: "/user/me",
    },
    update_user_profile: {
      method: "POST",
      url: "/user/me",
    },
  },
  user: {
    invite_member: {
      method: "POST",
      url: "/merchant-member/invite",
    },
    accept_invite: {
      method: "POST",
      url: "/merchant-member/invitation/accept",
    },
    resend_otp: {
      method: "POST",
      url: "/merchant-member/account/token/send",
    },
    resend_invitation: {
      method: "POST",
      url: "/merchant-member/invitation/resend",
    },
    rescind_invitation: {
      method: "POST",
      url: "/merchant-member/invitation/rescind",
    },
    verify_account: {
      method: "POST",
      url: "/merchant-member/account/verify",
    },
    update_account_status: {
      method: "PATCH",
      url: (uid: string) => `/merchant-member/${uid}/account/status`,
    },
    update_account_type: {
      method: "PATCH",
      url: (uid: string) => `/merchant-member/${uid}/account/user-type`,
    },
    list_members: {
      method: "GET",
      url: (page: number, size: number) =>
        `/merchant-member?page=${page}&page_size=${size}`,
    },
  },
  image_upload: {
    method: "POST",
    url: "/file/upload",
  },
};
