import React from "react";
import { tW } from "../../../utils/helpers";

type props = {
  children: React.ReactNode;
  onclickBackDrop?: () => void;
  position?: boolean;
  className?: string;
};

function SideModal({ children, onclickBackDrop, position, className }: props) {
  return (
    <div
      onClick={onclickBackDrop}
      className="fixed top-0 left-0 z-50 h-full w-full overflow-y-auto bg-base-10 backdrop-blur-sm"
    >
      <div
        className={tW(
          "flex h-full w-full items-start",
          position ? "justify-start" : "justify-end"
        )}
      >
        <section
          onClick={(e) => e.stopPropagation()}
          className={tW("min-h-screen w-5/6 md:w-1/2  bg-white", className)}
        >
          {children}
        </section>
      </div>
    </div>
  );
}

export default SideModal;
