import React, { useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import SignatureCanvas from "react-signature-canvas";
import { TbLoaderQuarter } from "react-icons/tb";
import {
  useAppDispatch,
  useAppSelector,
  // useCancelOnUnmount,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButton from "../../../../common/components/CustomButton";
import CustomCheckBox from "../../../../common/components/CustomCheckBox";
import CustomSignatureCanvas from "../../components/CustomSignatureCanvas";
import NoAuthority from "../../components/NoAuthority";
import AuthorityInitials from "../../components/AuthorityInitials";
import {
  completeAgreement,
  generateSignatureAgreementLink,
  generateSignatureRef,
} from "../../store/thunk";
import { getUserInfo } from "../../../settings/store/thunk";
import { iHandleChange, iHandleSubmit } from "../../types";
import Language from "../../../../common/utils/language/en";
import {
  clearSessionData,
  clearUserDetails,
  fetchSessionData,
} from "../../../../common/service/storage";
import routes from "../../../../common/routes";

const { authorize: PageDictionary } = Language.protected.onboardingModals;

const initialstate = {
  sla_recipient_email: "",
  use_initial_as_signature: true,
  initials: "",
  i_have_power: true,
  authorize_email: "",
  agree_terms: false,
  is_generating_link: false,
  generated_signature_id: "",
  is_generating_signature_ref: false,
  show_barcode: false,
  external_compliance_link: "",
  hide_have_power: false,
};

function Authorize() {
  const navigate = useNavigate();
  const location = useLocation();
  const background = location.state?.background;
  const pathname = background?.pathname;
  const signatureRef = useRef<SignatureCanvas>(null);
  const signatureContainerRef = useRef<HTMLDivElement>(null);
  const { company_id, token } = fetchSessionData();
  const [inData, setInData] = useState(initialstate);
  const dispatch = useAppDispatch();
  const { is_verified } = useAppSelector((state) => state.auth.user);
  // const cancelPendingRequests = useCancelOnUnmount();

  const disable_btn =
    (inData.i_have_power &&
      (!inData.sla_recipient_email || !inData.generated_signature_id)) ||
    !inData.agree_terms;

  useLayoutEffect(() => {
    const { company_id, token } = fetchSessionData();
    if (company_id && token) {
      setInData((prev) => ({ ...prev, hide_have_power: true }));
    }
  }, []);

  useEffectOnce(() => {
    return () => {
      // cancelPendingRequests();
    };
  });

  const handleGoToWelcome = () => {
    if (company_id && token) {
      clearSessionData();
      return navigate(routes.nonprotected.welcome, { replace: true });
    }
  };

  const changeHandler: iHandleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "initials":
        setInData((prev) => ({
          ...prev,
          [name]: value,
          show_barcode: false,
          generated_signature_id: "",
        }));

        break;

      default:
        setInData((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const clearHandler = () => {
    signatureRef?.current?.clear();
    setInData((prev) => ({
      ...prev,
      generated_signature_id: "",
      initials: "",
    }));
  };

  const toggleHandler: iHandleChange = (e) => {
    const { name } = e.target;
    switch (name) {
      case "use_initial_as_signature":
      case "agree_terms":
        setInData((prev) => ({ ...prev, [name]: !prev[name] }));
        break;
      case "i_have_power":
        if (inData.generated_signature_id) {
          clearHandler();
        }
        setInData((prev) => ({ ...prev, [name]: !prev[name] }));
        break;

      default:
        break;
    }
  };

  const showBarcodeHandler = () => {
    setInData((prev) => ({ ...prev, show_barcode: true }));
  };

  const generateSignatureHandler = () => {
    if (inData.is_generating_signature_ref) return;
    setInData((prev) => ({ ...prev, is_generating_signature_ref: true }));
    if (!signatureContainerRef?.current) {
      setInData((prev) => ({ ...prev, is_generating_signature_ref: false }));
      return;
    }

    html2canvas(signatureContainerRef.current)
      .then((canvas) => {
        const temp = canvas.toDataURL("image/png");
        dispatch(generateSignatureRef({ signature_image: temp }))
          .unwrap()
          .then((res) => {
            setInData((prev) => ({
              ...prev,
              generated_signature_id: res.signature_reference,
              hide_have_power: true,
            }));
          })
          .catch((err) => {
            if (err.message === "This agreement has already been signed") {
              clearSessionData();
              clearUserDetails();
              navigate(routes.nonprotected.welcome, { replace: true });
            }
          });
      })
      .catch((err) => console.table(err))
      .finally(() =>
        setInData((prev) => ({ ...prev, is_generating_signature_ref: false }))
      );
  };

  const cleanUpSignature = () => {
    setInData((prev) => ({
      ...prev,
      show_barcode: false,
      generated_signature_id: "",
      initials: "",
    }));
  };

  const handleGenrateExternalLink = () => {
    setInData((prev) => ({ ...prev, is_generating_link: true }));
    dispatch(generateSignatureAgreementLink({}))
      .unwrap()
      .then((res) =>
        setInData((prev) => ({
          ...prev,
          external_compliance_link: res.agreement_link,
        }))
      )
      .catch((err) => {
        if (err.message === "This agreement has already been signed") {
          handleGoToWelcome();
        }
      })
      .finally(() =>
        setInData((prev) => ({ ...prev, is_generating_link: false }))
      );
  };

  const closeModal = () => {
    navigate(pathname, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      completeAgreement({ sla_recipient_email: inData.sla_recipient_email })
    )
      .unwrap()
      .catch(() => {
        handleGoToWelcome();
      })
      .then(() => {
        handleGoToWelcome();
        if (is_verified) {
          dispatch(getUserInfo())
            .unwrap()
            .then(() =>
              navigate(routes.protected.onboarding.shareholders.index, {
                replace: true,
              })
            );
        }
      });
    // .finally(() => {
    //   const { company_id, token } = fetchSessionData();
    //   if (company_id && token) {
    //     return navigate(routes.nonprotected.welcome, { replace: true });
    //   }
    //   closeModal();
    // });
  };

  return (
    <SideModal onclickBackDrop={closeModal} className=" flex ">
      <form
        onSubmit={submitHandler}
        className="flex flex-1 flex-col bg-white pb-8 pt-9 pl-14 pr-22"
      >
        <div className="">
          <h3 className="text-2226 font-bold">{PageDictionary.page_title}</h3>
          <p className="mt-3 text-base font-medium leading-7">
            {PageDictionary.page_subtitle}
          </p>
        </div>
        <div className="flex flex-col gap-y-3.5 pt-7 pb-4.5">
          <CustomInput
            labelClassName="text-xs"
            label={PageDictionary.formLabels.email}
            type="email"
            name="sla_recipient_email"
            value={inData.sla_recipient_email}
            onChange={changeHandler}
            required
          />
          {inData.i_have_power ? (
            <div className="flex items-center gap-x-14">
              <CustomCheckBox
                checked={inData.use_initial_as_signature}
                label={
                  PageDictionary.formLabels.check_label.initial_as_signature
                }
                labelClassName="font-normal"
                name="use_initial_as_signature"
                onChange={toggleHandler}
              />
              <CustomCheckBox
                checked={!inData.use_initial_as_signature}
                label={PageDictionary.formLabels.check_label.draw_signature}
                labelClassName="font-normal"
                name="use_initial_as_signature"
                onChange={toggleHandler}
              />
            </div>
          ) : null}
          <div className="flex flex-col gap-y-3">
            {inData.i_have_power && inData.use_initial_as_signature ? (
              <AuthorityInitials
                changeHandler={changeHandler}
                generateSignatureHandler={generateSignatureHandler}
                generated_signature_id={inData.generated_signature_id}
                initials={inData.initials}
                signatureContainerRef={signatureContainerRef}
                cleanUpHandler={cleanUpSignature}
                show_barcode={inData.show_barcode}
                showBarcodeHandler={showBarcodeHandler}
                isGeneratingId={inData.is_generating_signature_ref}
              />
            ) : null}
            {inData.i_have_power && !inData.use_initial_as_signature ? (
              <CustomSignatureCanvas
                clearHandler={clearHandler}
                itmref={signatureRef}
                containerRef={signatureContainerRef}
                signatureValue={inData.generated_signature_id}
                generateSignatureHandler={generateSignatureHandler}
                isGeneratingId={inData.is_generating_signature_ref}
              />
            ) : null}
            {inData.hide_have_power === false && (
              <div className="flex items-center gap-x-2 text-sm font-bold">
                <CustomCheckBox
                  checked={!inData.i_have_power}
                  label={PageDictionary.formLabels.no_authority.question}
                  labelClassName="font-medium"
                  name="i_have_power"
                  onChange={toggleHandler}
                />
                <button
                  disabled={
                    inData.is_generating_link ||
                    inData.external_compliance_link !== "" ||
                    inData.i_have_power
                  }
                  onClick={handleGenrateExternalLink}
                  type="button"
                  className="text-cyan-20"
                >
                  {PageDictionary.formLabels.no_authority.action}
                </button>
                {inData.is_generating_link && (
                  <span className="animate-spin text-xl">
                    <TbLoaderQuarter />
                  </span>
                )}
              </div>
            )}
            {!inData.i_have_power && inData.external_compliance_link ? (
              <NoAuthority
                external_compliance_link={inData.external_compliance_link}
              />
            ) : null}
          </div>
        </div>

        <div className="flex flex-1 flex-col items-start justify-end">
          <CustomCheckBox
            checked={inData.agree_terms}
            name="agree_terms"
            label={PageDictionary.formLabels.read_terms}
            labelClassName="font-medium"
            onChange={toggleHandler}
          />
          <CustomButton
            disabled={disable_btn}
            className="mt-2.5   px-5 py-3.5 text-base leading-7"
          >
            {PageDictionary.formLabels.button}
          </CustomButton>
        </div>
      </form>
    </SideModal>
  );
}

export default Authorize;
