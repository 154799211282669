import React, { createContext } from "react";
import { TbLoaderQuarter } from "react-icons/tb";
import Pagination from "../CustomPagination";
import CustomToolTip from "../CustomToolTip";
import { tableHeadStyle } from "./helper";
import TableData from "./TableData";
import { IcontextTable, Itable } from "./type";
import { EmptyTableIcon } from "../../../static/images/icons";
import SlateTableData from "./SlateTableData";
import { tW } from "../../utils/helpers";

export const TableContext = createContext<IcontextTable | null>(null);

function CustomTable({
  cols,
  rows,
  layout_type,
  pageChange,
  currentPage,
  totalPageCount = 0,
  totalDataCount,
  isloading,
  emptyTableStyle,
  title,
  pageSize = 5,
  rowClick,
  parentRef,
}: Itable) {
  // const clickScrollToTop = useRef<HTMLTableRowElement | null>(null);
  const dummyArr = new Array(
    Math.abs(pageSize - (rows?.length ? rows?.length : 0))
  ).fill("0");

  return (
    <TableContext.Provider value={{ data: rows }}>
      <table
        className={tW(
          "w-full min-w-max text-left",
          isloading ? "animate-pulsed" : "",
          layout_type === "slate" ? "" : "border-separate border-spacing-y-3.5"
        )}
      >
        {title ? (
          <caption className="sticky top-0 z-[1] bg-white text-left text-xl font-bold text-base-50">
            {title}
          </caption>
        ) : null}
        {layout_type !== "slate" ? (
          <thead className="select-none ">
            <tr className="">
              {cols.map((col) => (
                <th
                  className={tW(
                    tableHeadStyle(layout_type),
                    title && layout_type === "reg" ? "top-7" : ""
                  )}
                  key={col.title}
                >
                  <span
                    className={tW(
                      col.tooltip ? "flex items-center gap-x-2.5" : ""
                    )}
                  >
                    {col.title}
                    {col.tooltip && <CustomToolTip tip={col.tooltip} />}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody className="">
          {isloading ? (
            <tr className="absolute inset-0 block ">
              <td
                colSpan={9999}
                className="flex h-full items-center  justify-center"
              >
                <div className=" grid justify-items-center">
                  <span className="inline-block animate-spin text-6xl">
                    <TbLoaderQuarter />
                  </span>
                  <p className="mt-3">fetching data...</p>
                </div>
              </td>
            </tr>
          ) : null}
          {rows?.length === 0 && emptyTableStyle ? (
            <tr>
              <td colSpan={9999} className={emptyTableStyle}>
                {!isloading && (
                  <div className="grid justify-items-center">
                    <span className="text-black">
                      <EmptyTableIcon scale={0.125} />
                    </span>
                    <p>No data Available</p>
                  </div>
                )}
              </td>
            </tr>
          ) : null}
          {layout_type !== "slate" && rows?.length > 0
            ? rows?.map((row, idx) => (
                <tr
                  key={idx}
                  // ref={idx === 0 ? clickScrollToTop : undefined}
                  className="text-sm text-gray-300 "
                  onClick={rowClick ? () => rowClick(row) : undefined}
                >
                  {cols.map((col, id) => (
                    <TableData
                      key={id}
                      row={row}
                      col={col}
                      layout_type={layout_type}
                    />
                  ))}
                </tr>
              ))
            : null}
          {layout_type !== "slate" && rows.length !== 0
            ? dummyArr.map((row, idx) => (
                <tr key={idx}>
                  {cols.map((col, id) => (
                    <td key={id} className="py-[1.625rem]"></td>
                  ))}
                </tr>
              ))
            : null}
          {layout_type === "slate"
            ? cols.map((col, idx) => (
                <tr key={idx}>
                  <th className="border-b py-6 px-1.5 text-left font-medium text-gray-610">
                    {col.title}
                  </th>
                  <SlateTableData row={rows} col={col} layout_type="slate" />
                </tr>
              ))
            : null}
        </tbody>
        {totalPageCount > 1 ? (
          <tfoot className="bg-white">
            {totalDataCount !== undefined && pageChange !== undefined ? (
              <tr>
                <td colSpan={9} className="sticky bottom-0 bg-white ">
                  <Pagination
                    setPage={pageChange}
                    tableRef={parentRef}
                    totalPages={totalPageCount}
                    dataCount={totalDataCount}
                    currentPage={currentPage}
                    isDisabled={isloading}
                    loading={isloading}
                  />
                </td>
              </tr>
            ) : null}
          </tfoot>
        ) : null}
      </table>
    </TableContext.Provider>
  );
}

export default CustomTable;
