import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCancelOnUnmount,
  useEffectOnce,
} from "../../../../common/hooks";
import { BiReset } from "react-icons/bi";
import { tW, toCurrency } from "../../../../common/utils/helpers";
import CustomButton from "../../../../common/components/CustomButton";
import Language from "../../../../common/utils/language/en";
import routes from "../../../../common/routes";
import {
  getExchangeRate,
  getWithdrawalThreshold,
  removeWithdrawalThreshold,
} from "../../store/thunk";

const { banner: PageDictionary } = Language.protected.Layout.utility_layout;

function Banner() {
  const { appid } = useParams();
  const { utility_balance, exchange_rate, theshold } = useAppSelector(
    (state) => state.utility
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const rate = exchange_rate?.data?.rate;
  const rate_loading = exchange_rate.loading;

  const loading = utility_balance.loading;
  const balance = utility_balance.data?.balance;

  const cancelPendingRequests = useCancelOnUnmount();

  useEffectOnce(() => {
    if (!appid) return;
    dispatch(getWithdrawalThreshold({ appid }));

    return () => {
      cancelPendingRequests();
    };
  });

  const removeThresholdHandler = () => {
    if (!appid) return;
    dispatch(removeWithdrawalThreshold({ appid }));
  };

  const navigateHandler = (path: string) => {
    if (!appid || rate_loading) return;

    if (!rate) {
      dispatch(getExchangeRate(appid))
        .unwrap()
        .then(() => {
          navigate(path, { state: { background: location } });
        });
    } else {
      navigate(path, { state: { background: location } });
    }
  };

  const thresholdHandler = () => {
    if (!appid) return;
    const path = routes.protected.applications.utility.threshold.link(appid);
    if (theshold.data?._id) {
      navigate(path, { state: { background: location } });
    } else {
      dispatch(getWithdrawalThreshold({ appid }))
        .unwrap()
        .then(() => navigate(path, { state: { background: location } }));
    }
  };

  const withdrawHandler = () => {
    if (!appid) return;
    const path = routes.protected.applications.utility.withdraw.link(appid);
    navigateHandler(path);
  };

  const fundHandler = () => {
    if (!appid) return;
    const path = routes.protected.applications.utility.fund.link(appid);
    navigateHandler(path);
  };

  useEffectOnce(() => {
    if (!appid) return;
  });

  return (
    <header className="flex items-center justify-between rounded-xl bg-peach-30 pt-7 pr-12 pb-2.5 pl-9 text-teal-40">
      <div className="flex flex-col gap-y-4.5">
        <div className="flex items-end gap-x-8">
          <div className="flex flex-col gap-y-3.5 pb-1.5">
            <h6>{PageDictionary.balance}</h6>
            <p
              className={tW(
                "text-2517 font-bold",
                loading ? "animate-pulse" : ""
              )}
            >
              {toCurrency(balance ? balance : 0)}
            </p>
          </div>
          <div className="flex items-center gap-x-5">
            <button
              className="rounded-10 bg-brown-50 py-3 px-5 text-xs font-medium text-white"
              onClick={withdrawHandler}
            >
              {PageDictionary.withdraw_btn}
            </button>
            <CustomButton
              // isloading={loading_exch}
              className="h-auto rounded-10 bg-teal-30 py-3 px-5 text-xs font-medium"
              onClick={fundHandler}
            >
              {PageDictionary.fund_btn}
            </CustomButton>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center gap-x-3.5">
            <CustomButton
              disabled={loading}
              isloading={theshold.loading}
              onClick={thresholdHandler}
              className="h-auto self-start rounded-10 border border-teal-40 bg-transparent py-1 px-7 text-xs text-teal-40 hover:bg-teal-40 hover:text-peach-30 disabled:hover:bg-teal-40 disabled:hover:text-peach-30"
            >
              {theshold.data?._id
                ? PageDictionary.threshold.update
                : PageDictionary.threshold.set}
            </CustomButton>
            {theshold.data?._id ? (
              <button onClick={removeThresholdHandler}>
                <span className="text-xl">
                  <BiReset />
                </span>
              </button>
            ) : null}
          </div>
          <p className="text-917 text-teal-40">
            {PageDictionary.threshold_text}
          </p>
        </div>
      </div>
    </header>
  );
}

export default Banner;
