const routes = {
  nonprotected: {
    welcome: "/",
    login: "/auth/login",
    register: "/auth/register",
    resetpassword: "/auth/resetpassword",
    forgotpassword: "/auth/forgotpassword",
    verified: "/auth/verified",
    verified_invite: "/auth/verified?member_invite=true",
    enter_otp: "/auth/enter-otp",
    verify_with_2fa: "/auth/2fa/verify",
    new_password: "/auth/new_password",
    reset_successful: "/auth/reset_successful",
    error_page: "*",
  },
  protected: {
    dashboard: "/dashboard",
    applications: {
      index: "/apps",
      create_new_app: {
        create: "/apps/create-app",
        checkout: "/apps/create-app/checkout",
        paymentsuccess: "/apps/create-app/success",
        update: {
          index: "/apps/create-app/:appid",
          link: (appid: string) => `/apps/create-app/${appid}`,
        },
      },

      wallet: {
        index: "/apps/:appid/wallet/",
        link: (appid: string) => `/apps/${appid}/wallet/`,
        fund: {
          nav: "/apps/wallet/fundwallet/bank",
          index: "/apps/wallet/fundwallet/bank/:appid",
          link: (appid: string) => `/apps/wallet/fundwallet/bank/${appid}`,
          bank: {
            index: "/apps/:appid/wallet/:txnid/fundwallet/bank",
            link: (appid: string, txnid: string) =>
              `/apps/${appid}/wallet/${txnid}/fundwallet/bank`,
          },
          crypto: {
            nav: "/apps/wallet/fundwallet/crypto",
            index: "/apps/wallet/fundwallet/crypto/:appid",
            link: (appid: string) => `/apps/wallet/fundwallet/crypto/${appid}`,
          },
          payment_processing: {
            index: "/apps/:appid/wallet/fundwallet/payment-processing",
            link: (appid: string) =>
              `/apps/${appid}/wallet/fundwallet/payment-processing`,
          },
          awaiting_confirmation: {
            index:
              "/apps/:appid/wallet/:txnid/fundwallet/awaiting-confirmation",
            link: (appid: string, txnid: string) =>
              `/apps/${appid}/wallet/${txnid}/fundwallet/awaiting-confirmation`,
          },
          success: {
            index: "/apps/:appid/wallet/fundwallet/success",
            link: (appid: string) => `/apps/${appid}/wallet/fundwallet/success`,
          },
        },
        withdrawl: {
          nav: "/apps/wallet/withdraw/bank",
          index: "/apps/wallet/withdraw/bank/:appid",
          link: (appid: string) => `/apps/wallet/withdraw/bank/${appid}`,
          authorize_bank: {
            index: "/apps/:appid/wallet/withdraw/confirm-bank",
            link: (appid: string) =>
              `/apps/${appid}/wallet/withdraw/confirm-bank`,
          },
          authorize_crypto: {
            nav: "/apps/wallet/withdraw/confirm-crypto",
            index: "/apps/wallet/withdraw/confirm-crypto/:appid",
            link: (appid: string) =>
              `/apps/wallet/withdraw/confirm-crypto/${appid}`,
          },
          success: {
            index: "/apps/:appid/wallet/withdraw/success",
            link: (appid: string) => `/apps/${appid}/wallet/withdraw/success`,
          },
          threshold: {
            index: "/apps/:appid/wallet/withdrawal-threshold",
            link: (appid: string) =>
              `/apps/${appid}/wallet/withdrawal-threshold`,
          },
        },
        settings: {
          // index: "/apps/:appid/wallet/settings",
          // link: (appid: string) => `/apps/${appid}/wallet/settings`,
          withdrawal_details: {
            crypto: {
              index: "/apps/:appid/wallet/settings/crypto",
              link: (appid: string) => `/apps/${appid}/wallet/settings/crypto`,
              add_crypto: {
                index: "/apps/:appid/wallet/settings/add-wallet-address",
                link: (appid: string) =>
                  `/apps/${appid}/wallet/settings/add-wallet-address`,
              },
            },
            bank: {
              index: "/apps/:appid/wallet/settings/bank",
              link: (appid: string) => `/apps/${appid}/wallet/settings/bank`,
              select_bank_type: {
                index: "/apps/:appid/wallet/settings/bank/type",
                link: (appid: string) =>
                  `/apps/${appid}/wallet/settings/bank/type`,
              },
              add_bank: {
                index: "/apps/:appid/wallet/settings/add-bank-account",
                link: (appid: string) =>
                  `/apps/${appid}/wallet/settings/add-bank-account`,
              },
            },
            confirm_action: {
              index: "/apps/:appid/wallet/settings/confirm-action/:accid",
              link: (appid: string, accid: string) =>
                `/apps/${appid}/wallet/settings/confirm-action/${accid}`,
            },
            success: {
              index: "/apps/:appid/wallet/settings/success",
              link: (appid: string) => `/apps/${appid}/wallet/settings/success`,
            },
          },
        },
      },
      api_settings: {
        index: "/apps/:appid/api-settings",
        link: (appid: string) => `/apps/${appid}/api-settings`,
        shown_api_setting: {
          index: "/apps/:appid/api-settings/s/confirm",
          link: (appid: string) => `/apps/${appid}/api-settings/s/confirm`,
        },
        regen_api_keys: {
          index: "/apps/:appid/api-settings/r/confirm",
          link: (appid: string) => `/apps/${appid}/api-settings/r/confirm`,
        },
        // download_api_keys: {
        //   index: "/apps/:appid/api-settings/d/confirm",
        //   link: (appid: string) => `/apps/${appid}/api-settings/d/confirm`,
        // },
      },
      checkout_settings: {
        index: "/apps/:appid/checkout-settings",
        link: (appid: string) => `/apps/${appid}/checkout-settings`,
      },
      transaction_information: {
        index: "/apps/:appid/transaction/:txnid",
        link: (appid: string, txnid: string) =>
          `/apps/${appid}/transaction/${txnid}`,
      },
      report_transaction: {
        index: "/apps/:appid/transaction/:txnid/report",
        link: (appid: string, txnid: string) =>
          `/apps/${appid}/transaction/${txnid}/report`,
      },
      outgoingTx: {
        index: "/apps/:appid/outgoing-transactions",
        link: (appid: string) => `/apps/${appid}/outgoing-transactions`,
      },
      incomingTx: {
        index: "/apps/:appid/incoming-settlements",
        link: (appid: string) => `/apps/${appid}/incoming-settlements`,
      },
      analytics: {
        index: "/apps/:appid/analytics",
        link: (appid: string) => `/apps/${appid}/analytics`,
      },
      include: {
        index: "/apps/:appid/include",
        link: (appid: string) => `/apps/${appid}/include`,
        toggle_include: {
          on: {
            index: "/apps/:appid/include/i/n/confirm",
            link: (appid: string) => `/apps/${appid}/include/i/n/confirm`,
          },
          off: {
            index: "/apps/:appid/include/i/f/confirm",
            link: (appid: string) => `/apps/${appid}/include/i/f/confirm`,
          },
        },
        wallet: {
          index: "/apps/:appid/include/money",
          link: (appid: string) => `/apps/${appid}/include/money`,
        },
        url: {
          index: "/apps/:appid/include/url",
          link: (appid: string) => `/apps/${appid}/include/url`,
        },
        pricing: {
          index: "/apps/:appid/include/pricing",
          link: (appid: string) => `/apps/${appid}/include/pricing`,
        },
        config: {
          index: "/apps/:appid/include/config",
          link: (appid: string) => `/apps/${appid}/include/config`,
        },
      },
      utility: {
        index: "/apps/:appid/utility",
        link: (appid: string) => `/apps/${appid}/utility`,
        threshold: {
          index: "/apps/:appid/utility/withdrawal-threshold",
          link: (appid: string) =>
            `/apps/${appid}/utility/withdrawal-threshold`,
        },
        fund: {
          index: "/apps/:appid/utility/fund",
          link: (appid: string) => `/apps/${appid}/utility/fund`,
        },
        withdraw: {
          index: "/apps/:appid/utility/withdraw",
          link: (appid: string) => `/apps/${appid}/utility/withdraw`,
        },

        history: {
          index: "/apps/:appid/utility/history",
          link: (appid: string) => `/apps/${appid}/utility/history`,
        },
        records: {
          index: "/apps/:appid/utility/records",
          link: (appid: string) => `/apps/${appid}/utility/records`,
        },
      },
      users: {
        index: "/apps/:appid/users",
        link: (appid: string) => `/apps/${appid}/users`,
        block_user: {
          index: "/apps/:appid/users/:userid/confirm-action",
          link: (appid: string, userid: string) =>
            `/apps/${appid}/users/${userid}/confirm-action`,
        },
      },
      policy: {
        index: "/apps/:appid/policy",
        link: (appid: string) => `/apps/${appid}/policy`,
        create_policy: {
          index: "/apps/:appid/policy/create-policy",
          link: (appid: string) => `/apps/${appid}/policy/create-policy`,
        },
        update_policy: {
          index: "/apps/:appid/policy/update-policy/:policyid",
          link: (appid: string, policyid: string) =>
            `/apps/${appid}/policy/update-policy/${policyid}`,
        },
        view_policy: {
          index: "/apps/:appid/policy/view-policy/:policyid",
          link: (appid: string, policyid: string) =>
            `/apps/${appid}/policy/view-policy/${policyid}`,
        },
        create_category: {
          index: "/apps/:appid/policy/create-category",
          link: (appid: string) => `/apps/${appid}/policy/create-category`,
        },
        update_category: {
          index: "/apps/:appid/policy/update-category/:catid",
          link: (appid: string, catid: string) =>
            `/apps/${appid}/policy/update-category/${catid}`,
        },
        block_user: {
          index: "/apps/:appid/policy/:userid/confirm-action",
          link: (appid: string, userid: string) =>
            `/apps/${appid}/policy/${userid}/confirm-action`,
        },
        risk_record: {
          index: "/apps/:appid/policy/:policyid/risk-record",
          link: (aid: string, pid: string) =>
            `/apps/${aid}/policy/${pid}/risk-record`,
        },
        notification_config: {
          index: "/apps/:appid/policy/:policyid/notification",
          link: (aid: string, pid: string) =>
            `/apps/${aid}/policy/${pid}/notification`,
          add: {
            index: "/apps/:appid/policy/:policyid/notification/add",
            link: (aid: string, pid: string) =>
              `/apps/${aid}/policy/${pid}/notification/add`,
          },
        },
      },
    },
    compliance: {
      index: "/compliance",
      business_document: {
        index: "/compliance/business-document",
        get_started: "/compliance/business-document/create",
        update_doc: {
          index: `/compliance/business-document/update`,
          link: (params: string) =>
            `/compliance/business-document/update?type=${params}`,
        },
      },
      shareholders: {
        index: "/compliance/shareholders",
        add: "/compliance/shareholders/add",
        remove: {
          index: "/compliance/shareholders/:shareid/remove",
          link: (shareid: string) =>
            `/compliance/shareholders/${shareid}/remove`,
        },
      },
      bussiness_profile: "/compliance/business-profile",
    },
    onboarding: {
      index: "/onboarding",
      terms: {
        index: "/onboarding/terms",
        agreement: "/onboarding/terms/agreement-info",
        authorize: "/onboarding/terms/authorize",
        authorize_with_link: "/onboarding/terms/authorize?external-link=true",
      },
      business_document: {
        index: "/onboarding/business-document",
        get_started: "/onboarding/business-document/create",
        update_doc: {
          index: `/onboarding/business-document/update`,
          link: (params: string) =>
            `/onboarding/business-document/update?type=${params}`,
        },
      },
      shareholders: {
        index: "/onboarding/shareholders",
        add: "/onboarding/shareholders/add",
        remove: {
          index: "/onboarding/shareholders/:shareid/remove",
          link: (shareid: string) =>
            `/onboarding/shareholders/${shareid}/remove`,
        },
      },
      bussiness_profile: "/onboarding/business-profile",
    },
    preview_app: "/preview",
    settings: {
      index: "/settings",
      security: "/settings/security",
      business_profile: "/settings/profile",
      transaction_country: "/settings/transaction-country",
      password_confrim: "/p/confirm",
      two_factor_confirm_password_update: "/fa/confirm",
      two_factor_toggle: "/fa/tg/confirm",
      two_factor_prompt: "/fa/pmt/confirm",
      activity_log: "/settings/activity_log",
      logout: "/logout",
    },
    user: {
      index: "/user",
      add_user: "/user/create",
      update_user: {
        index: "/user/:uid/update",
        link: (uid: string) => `/user/${uid}/update`,
      },
      suspend_user: {
        index: "/user/:uid/action",
        link: (uid: string) => `/user/${uid}/action`,
      },
      rescind_invite: {
        index: "/user/:uid/remove",
        link: (uid: string) => `/user/${uid}/remove`,
      },
    },
  },
};

export default routes;
