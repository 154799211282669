import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  clearUserDetails,
  storeRefreshToken,
  storeUserToken,
} from "../../../common/service/storage";
import { Iauth } from "../type";
import {
  loginUser,
  registerUser,
  verified,
  resetPassword,
  logUserOut,
  refreshUserToken,
  resendToken,
  send2faVerificationToken,
  verify2faToken,
} from "./thunk";
import {
  acceptInvite,
  inviteOtp,
  verifyInviteAccount,
} from "../../user/store/thunk";
import {
  completeAgreement,
  saveAgreementInfo,
} from "../../onboarding/store/thunk";
import { getUserInfo, updateUserProfile } from "../../settings/store/thunk";

const initialState: Iauth = {
  loading: false,
  error: null,
  company: null,
  otp_loading: false,
  user: {
    _id: "",
    model_id: "",
    created_at: "",
    updated_at: "",
    is_active: false,
    deleted_at: "",
    extra_fields: {},
    is_2fa_enabled: false,
    is_deleted: false,
    meta_data: "",
    email: "",
    role: "",
    is_verified: false,
    country: "",
    company: "",
    user_types: [],
  },
  isRefreshingToken: false,
  token: {
    exp: 0,
    iat: 0,
    jti: "",
    token_type: "",
    user_id: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = { ...initialState.user };
    },

    resetAuthError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logUserOut.fulfilled, (state) => {
        clearUserDetails();
        state.user = initialState.user;
        state.token = initialState.token;
        state.company = initialState.company;
      })
      .addCase(refreshUserToken.fulfilled, (state, action) => {
        storeUserToken(action.payload.access);
        storeRefreshToken(action.payload.refresh);
        state.token = jwt_decode(action.payload.access);
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.company = payload.company;
        state.user = payload.user;
      })
      .addCase(updateUserProfile.fulfilled, (state, { meta }) => {
        state.user.is_2fa_enabled = meta.arg.is_2fa_enabled;
      })
      .addCase(saveAgreementInfo.fulfilled, (state, { payload }) => {
        if (state.company) {
          state.company.agreement_info = payload;
        }
      })
      .addCase(completeAgreement.fulfilled, (state, { payload }) => {
        if (state?.company?.agreement_info?.signature?._id) {
          state.company.agreement_info.signature.complete = true;
        }
      })
      .addCase(refreshUserToken.pending, (state) => {
        state.isRefreshingToken = true;
      })
      .addMatcher(
        isAnyOf(refreshUserToken.fulfilled, refreshUserToken.rejected),
        (state) => {
          state.isRefreshingToken = initialState.isRefreshingToken;
        }
      )
      .addMatcher(
        isAnyOf(verified.fulfilled, verifyInviteAccount.fulfilled),
        (state) => {
          state.user = { ...state.user, is_verified: true };
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.fulfilled,
          registerUser.fulfilled,
          acceptInvite.fulfilled,
          verify2faToken.fulfilled
        ),
        (state, { payload }) => {
          if (payload?.token) {
            storeUserToken(payload.token.access);
            storeRefreshToken(payload.token.refresh);
            state.token = jwt_decode(payload.token.access);
          }
          if (payload.user) {
            state.user = { ...payload.user };
          }
          if (payload?.company) {
            state.company = payload.company;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.fulfilled,
          verified.fulfilled,
          resetPassword.fulfilled,
          registerUser.fulfilled,
          logUserOut.fulfilled,
          loginUser.pending,
          verified.pending,
          resetPassword.pending,
          registerUser.pending,
          logUserOut.pending
        ),
        (state) => {
          state.error = initialState.error;
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.rejected,
          verified.rejected,
          registerUser.rejected,
          resetPassword.rejected,
          logUserOut.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          inviteOtp.pending,
          resendToken.pending,
          send2faVerificationToken.pending
        ),
        (state) => {
          state.otp_loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          inviteOtp.rejected,
          inviteOtp.fulfilled,
          resendToken.rejected,
          resendToken.fulfilled,
          send2faVerificationToken.rejected,
          send2faVerificationToken.fulfilled
        ),
        (state) => {
          state.otp_loading = initialState.otp_loading;
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.pending,
          verified.pending,
          resetPassword.pending,
          logUserOut.pending,
          registerUser.pending,
          acceptInvite.pending,
          verifyInviteAccount.pending,
          inviteOtp.pending,
          verify2faToken.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.rejected,
          loginUser.fulfilled,
          registerUser.rejected,
          registerUser.fulfilled,
          verified.rejected,
          verified.fulfilled,
          resetPassword.rejected,
          resetPassword.fulfilled,
          logUserOut.rejected,
          logUserOut.fulfilled,
          acceptInvite.rejected,
          acceptInvite.fulfilled,
          verifyInviteAccount.rejected,
          verifyInviteAccount.fulfilled,
          inviteOtp.rejected,
          inviteOtp.fulfilled,
          verify2faToken.rejected,
          verify2faToken.fulfilled
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      );
  },
});

export const { resetAuthError, resetUser } = authSlice.actions;

export default authSlice.reducer;
