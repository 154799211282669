import cuid from "cuid";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { validationRules } from "../../../../common/utils/helpers";
import MediaUpload from "../../../applications/components/MediaUpload";
import { addShareholder } from "../../store/thunk";
import { iFileToUpload, iHandleChange } from "../../types";
import Language from "../../../../common/utils/language/en";

const initialstate = {
  name: "",
  email: "",
  address: "",
  means_of_identity: [],
  proof_of_address: [],
};
const initialerrorstate = {
  name: false,
  email: false,
  address: false,
  means_of_identity: false,
  proof_of_address: false,
};

type stateType = {
  name: string;
  email: string;
  address: string;
  means_of_identity: iFileToUpload;
  proof_of_address: iFileToUpload;
};

const { shareholder_form: PageDictionary } =
  Language.protected.compalinaceModals;

function ShareholderForm() {
  const navigate = useNavigate();
  const location = useLocation().state.background.pathname;
  const [inData, setInData] = useState<stateType>(initialstate);
  const [inError, setInError] = useState(initialerrorstate);
  const { loading } = useAppSelector((state) => state.compliance.shareholders);
  const dispatch = useAppDispatch();

  const disable_submit_btn =
    Object.values(inData).includes("") ||
    inData.means_of_identity.length === 0 ||
    inData.proof_of_address.length === 0;

  const onDropShareholderId = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setInData((prev) => ({
          ...prev,
          means_of_identity: [{ id: cuid(), data: file, name: file.name }],
        }));
        setInError((prev) => ({ ...prev, means_of_identity: false }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  const onDropShareholderAddress = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setInData((prev) => ({
          ...prev,
          proof_of_address: [{ id: cuid(), data: file, name: file.name }],
        }));
        setInError((prev) => ({ ...prev, proof_of_address: false }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onRemoveShareholderId = (id: string) => {
    const temp = inData.means_of_identity.filter((img: any) => img.id !== id);
    setInData((prev) => ({
      ...prev,
      means_of_identity: [...temp],
    }));
    setInError((prev) => ({ ...prev, means_of_identity: true }));
  };
  const onRemoveShareholderAddress = (id: string) => {
    const temp = inData.proof_of_address.filter((img: any) => img.id !== id);
    setInData((prev) => ({
      ...prev,
      proof_of_address: [...temp],
    }));
    setInError((prev) => ({ ...prev, proof_of_address: true }));
  };

  const changeHandler: iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const closeModal = () => {
    if (loading) return;
    navigate(location, { replace: true });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inData.means_of_identity[0].data || !inData.proof_of_address[0].data) {
      return;
    }
    const data = {
      name: inData.name,
      email: inData.email,
      address: inData.address,
      means_of_identity: inData.means_of_identity[0].data,
      proof_of_address: inData.proof_of_address[0].data,
    };
    dispatch(addShareholder(data)).unwrap().then(closeModal);
  };

  return (
    <SideModal onclickBackDrop={closeModal} className="p-8 md:pt-15 md:pl-14 md:pr-28">
      <h3 className="text-4xl">{PageDictionary.title}</h3>
      <form onSubmit={submitHandler} className="flex flex-col gap-y-8 pt-11">
        <CustomInput
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          label={PageDictionary.form_elements.name}
          name="name"
          value={inData.name}
          onChange={changeHandler}
          disabled={loading}
          rules={validationRules.string}
          haserror={inError.name}
        />
        <CustomInput
          label={PageDictionary.form_elements.email}
          type="email"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          name="email"
          value={inData.email}
          onChange={changeHandler}
          disabled={loading}
          rules={validationRules.email}
          haserror={inError.email}
        />
        <div className="flex items-start gap-x-14">
          <MediaUpload
            title={PageDictionary.form_elements.shareholder_id}
            images={inData.means_of_identity}
            onDrop={onDropShareholderId}
            onRemoveMedia={onRemoveShareholderId}
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            name="means_of_identity"
            supportFormatText={PageDictionary.form_elements.upload_support_text}
            hasserror={inError.means_of_identity}
          />
          <MediaUpload
            title={PageDictionary.form_elements.proof_address}
            images={inData.proof_of_address}
            onDrop={onDropShareholderAddress}
            onRemoveMedia={onRemoveShareholderAddress}
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            supportFormatText={PageDictionary.form_elements.upload_support_text}
            name="proof_of_address"
            hasserror={inError.proof_of_address}
          />
        </div>
        <CustomInput
          label={PageDictionary.form_elements.address}
          type="text"
          className="h-12 !py-1.5"
          containerClassName=""
          labelClassName="mb-2.5"
          name="address"
          value={inData.address}
          onChange={changeHandler}
          disabled={loading}
          rules={validationRules.string}
          haserror={inError.address}
        />
        <CustomButton
          isloading={loading}
          disabled={disable_submit_btn}
          className="mt-8 self-start px-[3.25rem] py-3.5 text-base leading-7"
        >
          {PageDictionary.form_elements.submit}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default ShareholderForm;
