import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SuccessSideModal from "../../../../../../common/components/CustomModal/SuccessSideModal";
import routes from "../../../../../../common/routes";
import Language from "../../../../../../common/utils/language/en";

const { WithdrawalSuccess: PageDictionary } =
  Language.protected.walletModals.withdrawal;

function WithdrawSuccess() {
  const { appid } = useParams();
  const navigate = useNavigate();
  const backToApplication = () => {
    if (appid) {
      const path = routes.protected.applications.wallet.link(appid);
      navigate(path, { replace: true });
    }
  };
  return (
    <SuccessSideModal
      action={backToApplication}
      title={PageDictionary.title}
      subtitle={PageDictionary.subtitle}
      cta={PageDictionary.submit_btn}
    />
  );
}

export default WithdrawSuccess;
