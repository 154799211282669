import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import * as tp from "../types";

export const getUtility = createAsyncThunk(
  "utility/getUtility",
  async ({ appid }: tp.ThunkData, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getUtility(appid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const getExchangeRate = createAsyncThunk(
  "utility/getExchangeRate",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getExchangeRate(id)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const fundUtilityWallet = createAsyncThunk(
  "utility/fundUtilityWallet",
  async (
    { appid, data }: tp.IWalletTransThunk,
    { dispatch, rejectWithValue }
  ) => {
    return service
      .fundUtilityWallet(appid, data)
      .then((res) => {
        customToast.success(res.message);
        dispatch(getUtility({ appid }));
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const withdrawUtilityWallet = createAsyncThunk(
  "utility/withdrawUtilityWallet",
  async (
    { appid, data }: tp.IWalletTransThunk,
    { dispatch, rejectWithValue }
  ) => {
    return service
      .withdrawUtilityWallet(appid, data)
      .then((res) => {
        customToast.success(res.message);
        dispatch(getUtility({ appid }));
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const getWithdrawalThreshold = createAsyncThunk(
  "utility/getWithdrawalThreshold",
  async ({ appid }: tp.ThunkData, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getWithdrawalThreshold(appid)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const setWithdrawalThreshold = createAsyncThunk(
  "utility/setWithdrawalThreshold",
  async (
    { appid, data }: tp.IsetThresholdThunk,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service
      .setWithdrawalThreshold(appid, data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const removeWithdrawalThreshold = createAsyncThunk(
  "utility/removeWithdrawalThreshold",
  async ({ appid }: tp.ThunkData, { dispatch, rejectWithValue }) => {
    return service
      .removeWithdrawalThreshold(appid)
      .then((res) => {
        customToast.success(res.message);
        dispatch(getWithdrawalThreshold({ appid }));
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const listUtilityCategory = createAsyncThunk(
  "utility/listUtilityCategory",
  async ({ appid }: tp.ThunkData, { fulfillWithValue, rejectWithValue }) => {
    return service
      .listUtilityCategory(appid)
      .then((res) => {
        if (res.data) {
          return fulfillWithValue(res.data).payload;
        } else {
          return fulfillWithValue([]).payload;
        }
      })
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
// export const listSingleUtilityCategory = createAsyncThunk(
//   "utility/listSingleUtilityCategory",
//   async ({ appid }: tp.ThunkData, { dispatch, rejectWithValue }) => {
//     return service
//       .listSingleUtilityCategory(appid)
//       .then((res) => {
//         customToast.success(res.message);
//         dispatch(getWithdrawalThreshold({ appid }));
//       })
//       .catch((err) => {
//         customToast.error(err?.message);
//         return rejectWithValue(err);
//       });
//   }
// );
export const getUtilityRecords = createAsyncThunk(
  "utility/getUtilityRecords",
  async (req: tp.IlistTransaction, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getUtilityRecords(req)
      .then((res) => fulfillWithValue(res).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const getUtilityHistory = createAsyncThunk(
  "utility/getUtilityHistory",
  async (req: tp.IlistTransaction, { fulfillWithValue, rejectWithValue }) => {
    return service
      .getUtilityHistory(req)
      .then((res) => fulfillWithValue(res).payload)
      .catch((err) => {
        customToast.error(err?.message);
        return rejectWithValue(err);
      });
  }
);
