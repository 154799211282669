import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import ConfirmShareholderAction from "./ConfirmShareholderAction";
import ShareholderForm from "./ShareholderForm";
import UploadBusinessDocument from "./UploadBusinessDocument";

function ComplianceModal() {
  return (
    <Routes>
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.compliance.business_document.get_started}
      />
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.compliance.business_document.update_doc.index}
      />
      <Route
        element={<ShareholderForm />}
        path={routes.protected.compliance.shareholders.add}
      />
      <Route
        element={<ConfirmShareholderAction />}
        path={routes.protected.compliance.shareholders.remove.index}
      />
    </Routes>
  );
}

export default ComplianceModal;
