import cuid from "cuid";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  useEffectOnce,
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks";
import CustomButton from "../../../../../common/components/CustomButton";
import CustomCheckBox from "../../../../../common/components/CustomCheckBox";
import CustomInput from "../../../../../common/components/CustomInput";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import {
  Select,
  SelectItem,
} from "../../../../../common/components/CustomSelect";
import MediaUpload from "../../../components/MediaUpload";
import CustomTextArea from "../../../../../common/components/CustomTextArea";
import customToast from "../../../../../common/components/CustomToast";
import { tW, validationRules } from "../../../../../common/utils/helpers";
import { addNewApp, updateExistingApp } from "../../../store/thunk";
import * as tp from "../../../types";
import routes from "../../../../../common/routes";
import {
  diffrenceHandler,
  errorstate,
  handleRestoreData,
  header_btn,
  initialstate,
} from "./helper";
import Language from "../../../../../common/utils/language/en";

const { CreateApplicationForm: PageDictionary } =
  Language.protected.CreateApplicationModal;

export function CreateApplicationForm() {
  const { appid } = useParams();
  const location = useLocation().state.background;
  const navigate = useNavigate();
  const restoreAppInfo = useAppSelector((state) =>
    state.app.allApps?.data?.find((app) => app._id === appid)
  );
  const [state, setState] = useState<tp.IcreateAppState>(initialstate);
  const [inError, setInError] = useState(errorstate);
  const { loading } = useAppSelector((state) => state.app.allApps);
  const dispatch = useAppDispatch();
  const [editState, seteditState] = useState({
    button: PageDictionary.form.submit_form.submit,
    disableInput: false,
  });
  const submitAttempt = useRef(false);

  const { diffrence, count } = diffrenceHandler(state, restoreAppInfo);

  const submitDisable =
    !state.confirm_kyc &&
    editState.button === PageDictionary.form.submit_form.submit;

  const updateDisable =
    editState.button === PageDictionary.form.submit_form.update && count === 0;

  const errorDisable = Object.values(inError).includes(true);

  useEffectOnce(() => {
    if (!appid || !restoreAppInfo) return;

    seteditState((prev) => ({
      ...prev,
      button: PageDictionary.form.submit_form.update,
      disableInput: false,
    }));

    handleRestoreData(state, setState, restoreAppInfo);
  });

  const onDropApp = useCallback((acceptedFiles: any, a: any, b: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setState((prev) => ({
          ...prev,
          logo: [{ id: cuid(), src: file, name: file.name, url: file }],
        }));
        setInError((prev) => ({ ...prev, logo: false }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  const onDropWallet = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setState((prev) => ({
          ...prev,
          parent_company_logo: [
            { id: cuid(), src: file, name: file.name, url: file },
          ],
        }));
        setInError((prev) => ({ ...prev, parent_company_logo: false }));
      };

      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const removeImageHandler = (
    id: string,
    type: "logo" | "parent_company_logo"
  ) => {
    const temp = state.logo.filter((img) => img.id !== id);
    setState((prev) => ({
      ...prev,
      [type]: [...temp],
    }));
    setInError((prev) => ({ ...prev, [type]: true }));
  };

  const checkToggleHandler: tp.iHandleChange = (e) => {
    const { name } = e.target;
    switch (name) {
      case "confirm_kyc":
        setState((prev) => ({
          ...prev,
          confirm_kyc: !prev.confirm_kyc,
        }));
        break;

      default:
        setState((prev) => ({
          ...prev,
          channels: { ...prev.channels, [name]: !prev.channels[name] },
        }));
        break;
    }
  };

  const fieldSetBlurHandler = (
    e: React.FocusEvent<HTMLFieldSetElement, Element>
  ) => {
    if (Object.values(state.channels).includes(true) === false) {
      setInError((prev) => ({ ...prev, channels: true }));
    } else {
      setInError((prev) => ({ ...prev, channels: false }));
    }
  };

  const selectHandler = (val: string) => {
    setState((prev) => ({ ...prev, industry: val }));
  };

  const focusHandler = (type: string) => {
    if (type !== "industry") return;
    if (state[type] === "") {
      setInError((prev) => ({ ...prev, [type]: true }));
    } else {
      setInError((prev) => ({ ...prev, [type]: false }));
    }
  };

  const changeHandler: tp.iHandleChange = useCallback((e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  }, []);

  const backDropHandler = () => {
    if (loading) {
      return;
    }
    navigate(routes.protected.applications.index, { replace: true });
  };

  const submitHandler: tp.iHandleSubmit = (e) => {
    e.preventDefault();

    if (
      (submitAttempt.current === false &&
        (Object.values(state).includes("") ||
          state.logo.length === 0 ||
          state.parent_company_logo.length === 0)) ||
      Object.values(state.channels).includes(true) === false
    ) {
      (Object.keys(state) as Array<keyof typeof state>)?.forEach((key) => {
        switch (key) {
          case "channels":
            if (Object.values(state.channels).includes(true) === false) {
              setInError((prev) => ({ ...prev, [key]: true }));
            }
            break;
          case "logo":
          case "parent_company_logo":
            if (state[key].length === 0) {
              setInError((prev) => ({
                ...prev,
                [key]: true,
              }));
            }

            break;
          case "confirm_kyc":
            if (state[key] === false) {
              setInError((prev) => ({
                ...prev,
                [key]:
                  editState.button === PageDictionary.form.submit_form.submit
                    ? true
                    : false,
              }));
            }
            break;

          default:
            if (state[key] === "") {
              setInError((prev) => ({ ...prev, [key]: true }));
            }
            break;
        }
      });
      submitAttempt.current = true;
      customToast.error(PageDictionary.error.fill_empty_fields);
      return;
    }

    if (editState.button === PageDictionary.form.submit_form.submit) {
      const data = {
        ...state,
        logo: state.logo[0]?.url,
        parent_company_logo: state.parent_company_logo[0]?.url,
        channels: (
          Object.keys(state.channels) as Array<keyof typeof state.channels>
        ).map((key) => ({
          name: key.toUpperCase(),
          status: state.channels[key],
        })),
      };

      dispatch(addNewApp(data)).unwrap().finally(backDropHandler);
    }

    if (!appid) return;

    if (count === 0) {
      return navigate(location.pathname, { replace: true });
    }

    if (editState.button === PageDictionary.form.submit_form.update) {
      const req = { data: diffrence, appid };
      dispatch(updateExistingApp(req)).unwrap().finally(backDropHandler);
    }
  };

  return (
    <SideModal
      onclickBackDrop={backDropHandler}
      className="relative p-8 md:pl-14 md:pr-28 md:pb-24"
    >
      <header className="sticky top-0 z-10 bg-white pb-4 pt-16">
        <h3 className="text-4xl font-bold">
          {`${
            appid ? PageDictionary.title.update : PageDictionary.title.create
          }`}
        </h3>
      </header>
      <form
        onSubmit={submitHandler}
        className="z-[9] flex flex-col gap-y-7 pt-11"
      >
        <CustomInput
          label={PageDictionary.form.app_name_label}
          name="name"
          value={state.name}
          onChange={changeHandler}
          disabled={loading || editState.disableInput}
          rules={validationRules.string}
          haserror={inError.name}
        />
        <CustomInput
          label={PageDictionary.form.app_owner_label}
          name="parent_company"
          value={state.parent_company}
          onChange={changeHandler}
          disabled={loading || editState.disableInput}
          rules={validationRules.string}
          haserror={inError.parent_company}
        />
        <div className="flex flex-wrap items-start gap-x-14">
          <MediaUpload
            title={PageDictionary.form.app_logo}
            images={state.logo}
            onDrop={onDropApp}
            onRemoveMedia={(id) => removeImageHandler(id, "logo")}
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            name="logo"
            hasserror={inError.logo}
            disabled={loading || editState.disableInput}
            supportFormatText={PageDictionary.form.upload_support_text}
          />
          <MediaUpload
            title={PageDictionary.form.app_owner_logo}
            images={state.parent_company_logo}
            onDrop={onDropWallet}
            onRemoveMedia={(id) =>
              removeImageHandler(id, "parent_company_logo")
            }
            accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
            name="parent_company_logo"
            hasserror={inError.parent_company_logo}
            disabled={loading || editState.disableInput}
            supportFormatText={PageDictionary.form.upload_support_text}
          />
        </div>
        <Select
          label={PageDictionary.form.app_industry}
          name="industry"
          onChange={selectHandler}
          disabled={loading || editState.disableInput}
          value={state.industry}
          onFocus={(e) => focusHandler("industry")}
          hasError={inError.industry}
        >
          {PageDictionary.form.app_industry_list.map((itm) => (
            <SelectItem key={itm} value={itm}>
              <span>{itm}</span>
            </SelectItem>
          ))}
        </Select>
        <CustomTextArea
          label={PageDictionary.form.app_description}
          name="description"
          cols={30}
          rows={5}
          value={state.description}
          onChange={changeHandler}
          disabled={loading || editState.disableInput}
          rules={validationRules.string}
          haserror={inError.description}
        />
        <fieldset
          className={tW(
            "rounded-xl border",
            inError.channels ? "border-red-10" : "border-transparent"
          )}
          onBlur={fieldSetBlurHandler}
        >
          <legend className="inline-block bg-white pl-0.5 pr-2 text-base font-medium text-base-20">
            {PageDictionary.form.app_channels.title}
          </legend>
          <div className="flex flex-col gap-y-6 px-2 pt-7 pb-2">
            {header_btn.map((itm, idx) => (
              <CustomCheckBox
                key={idx}
                checked={state.channels[itm.name]}
                label={itm.label}
                labelClassName="text-1414 font-normal text-teal-20"
                sclassName="rounded-0 w-4 h-4"
                name={itm.name}
                onChange={checkToggleHandler}
                disabled={loading || editState.disableInput}
              />
            ))}
          </div>
        </fieldset>
        <CustomInput
          label={PageDictionary.form.app_url}
          name="application_url"
          value={state.application_url}
          onChange={changeHandler}
          type="url"
          disabled={loading || editState.disableInput}
          rules={validationRules.website}
          haserror={inError.application_url}
          placeholder="https://api.finswich.io/sandbox"
        />
        <CustomInput
          label={PageDictionary.form.app_user_base}
          name="user_base"
          value={state.user_base}
          onChange={changeHandler}
          disabled={loading || editState.disableInput}
          rules={validationRules.number}
          haserror={inError.user_base}
        />

        {editState.button === "Submit" && (
          <div className="mt-5">
            <CustomCheckBox
              checked={state.confirm_kyc}
              name="confirm_kyc"
              onChange={checkToggleHandler}
              labelClassName="gap-x-4 leading-5"
              sclassName="w-4 h-4 rounded-0"
              label={PageDictionary.form.confirm_kyc}
            />
          </div>
        )}

        <CustomButton
          disabled={submitDisable || updateDisable || errorDisable}
          className="mt-2.5 self-start px-[3.25rem] py-3.5 text-base leading-7"
          isloading={loading}
        >
          {editState.button}
        </CustomButton>
      </form>
    </SideModal>
  );
}
